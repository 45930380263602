import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import store from 'Redux/store';
import isPrerender from 'Helpers/SEO/is-prerender';
import { MetaTag } from 'Helpers/SEO/Tags';
import waitForSentry from 'Helpers/Sentry/wait-for-sentry';

// Usually the reason on PromiseRejectionEvent is not an error, so Sentry cannot handle
// it. This class converts the object back into an error that Sentry can process
// https://sentry.zendesk.com/hc/en-us/articles/26649121915291-I-m-getting-Object-captured-as-exception-with-keys
class ComponentDidCatchError extends Error {
  constructor(reason) {
    super(reason.message);
    this.name = reason.name || this.constructor.name;
    this.message = reason.message;
    this.stack = reason.stack || reason.transporterStackTrace || new Error().stack;
  }
}

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    hideForm: PropTypes.bool,
    noChunkRedirect: PropTypes.bool,
  }

  static defaultProps = {
    children: <React.Fragment />,
    hideForm: false,
    noChunkRedirect: false,
  }

  // Don't return on exotic errors to stop error page flashing up
  static getDerivedStateFromError(error) {
    if (error &&
      ((error.message && error.message.match(/Loading chunk (.*) failed|promise.all is not|promise.resolve is not/)) ||
      (error.code && error.code.includes && error.code.includes('CHUNK'))) &&
      !isPrerender()
    ) {
      return { error: false };
    }

    if (error && error.message === '"validUntil" parameter expired (less than current date)') {
      return { error: false };
    }

    // Usually when removing an element from the DOM that is no longer there
    // It happens with hoofd useScript especially. If there is a problem removing
    // an element from the DOM it is likely not fatal so we can ignore it
    if (error && error.name === 'NotFoundError') {
      return { error: false };
    }

    if (error && error.message && error.message.match(/QuotaExceededError/)) {
      return { error: false };
    }

    store.dispatch({ type: 'frontend/error/ERROR_PAGE' });

    return { error: true };
  }

  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error, errorInfo) {
    if (error &&
      ((error.message && error.message.match(/Loading chunk (.*) failed|promise.all is not|promise.resolve is not/)) ||
      (error.code && error.code.includes && error.code.includes('CHUNK'))) &&
      !isPrerender()
    ) {
      let url = window.location.href;

      if (!url.includes('chunkRedirect=1') && !this.props.noChunkRedirect) {
        url = `${url}${url.includes('?') ? '&' : '?'}chunkRedirect=1`;
        window.location.href = url;

        return false;
      }
    }

    if (error && error.message === '"validUntil" parameter expired (less than current date)') {
      let url = window.location.href;

      if (!url.includes('algoliaKeyExpired=1')) {
        url = `${url}${url.includes('?') ? '&' : '?'}algoliaKeyExpired=1`;
        window.location.href = url;

        return false;
      }
      window.location.reload();
      return false;
    }

    if (error && error.name === 'NotFoundError') {
      return false;
    }

    if (error && error.message && error.message.match(/QuotaExceededError/)) {
      let url = window.location.href;

      if (!url.includes('QuotaExceededError=1')) {
        url = `${url}${url.includes('?') ? '&' : '?'}QuotaExceededError=1`;
        localStorage.removeItem('recentlyViewed');
        localStorage.removeItem('bsvoucher');
        localStorage.removeItem('MOE_DATA');
        window.location.href = url;

        return false;
      }
    }
    waitForSentry((Sentry) => {
      if (error instanceof Error) {
        const eventId = Sentry.captureException(error, { extra: errorInfo });
        this.setState({ eventId });
      } else {
        const realError = new ComponentDidCatchError(error);
        const eventId = Sentry.captureException(realError, { extra: errorInfo });
        this.setState({ eventId });
      }
    });
    return true;
  }

  render() {
    if (this.state.error) {
      if (this.props.hideForm) {
        return (
          <MetaTag name="prerender-status-code" content="503" />
        );
      }
      return (
        <div className="text-center my-5 container">
          <MetaTag name="prerender-status-code" content="503" />
          <h2 className="display-1 text-primary not-found-title">
            <FormattedMessage id="error.title" defaultMessage="Unexpected Error - Something's wrong" />
          </h2>
          <p className="my-4">
            <FormattedMessage id="error.description" defaultMessage="It looks as though something on our website is broken. An error report has been sent to our developers. Please refresh and try again, or send us some extra feedback." />
          </p>
          {!!this.state.eventId &&
            <button
              className="btn btn-lg btn-primary mb-2 mr-sm-2"
              onClick={() =>
                waitForSentry(Sentry => Sentry.showReportDialog({
                  eventId: this.state.eventId,
                  lang: window.locale.substring(0, 2),
                }))}
            >
              <FormattedMessage id="error.feebackbutton" defaultMessage="Tell us what happened" />
            </button>
          }
          <button className="btn btn-lg btn-primary mb-2 ml-sm-2" onClick={() => window.location.reload(true)}>
            <FormattedMessage id="error.reloadbutton" defaultMessage="Refresh the page and try again" />
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
