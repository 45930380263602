import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function DlcBadge({ size }) {
  return (
    <div className={classNames('dlc-badge', { [`dlc-badge--${size}`]: size !== '' })}>
      DLC
    </div>
  );
}

DlcBadge.propTypes = {
  size: PropTypes.string,
};

DlcBadge.defaultProps = {
  size: '',
};
