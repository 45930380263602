import React from 'react';
import PropTypes from 'prop-types';

import env from 'Env';
import imgixConfig from 'Config/imgix';

function FixedImg({ width, src, alt, className, folder, subfolder, lazy }) {
  let subfolderString = subfolder;

  if (folder === 'product' && !subfolder) {
    subfolderString = 'original';
  }

  if (/\.svg$/.test(src)) {
    const root = env.srcCdnUrl || env.cdnUrl;
    const imgSrc = `${root}/${folder}${subfolderString ? '/' : ''}${subfolderString}/${src}`;


    return (<img
      src={imgSrc}
      alt={alt}
      className={`img-fluid img-full ${className}`}
      loading={lazy ? 'lazy' : 'eager'}
      fetchpriority={lazy ? undefined : 'high'}
    />);
  }

  const imgSrc = `${env.cdnUrl}/${folder}${subfolderString ? '/' : ''}${subfolderString}/${src}`;

  return (
    <img
      srcSet={`
        ${imgSrc}?${imgixConfig}&fit=fillmax${width ? '&w=' : ''}${width} 1x,
        ${imgSrc}?${imgixConfig}&fit=fillmax${width ? '&w=' : ''}${width}&dpr=2 2x,
        ${imgSrc}?${imgixConfig}&fit=fillmax${width ? '&w=' : ''}${width}&dpr=3 3x
      `}
      src={`${imgSrc}?${imgixConfig}&fit=fillmax${width ? '&w=' : ''}${width}`}
      alt={alt}
      loading={lazy ? 'lazy' : 'eager'}
      fetchpriority={lazy ? 'low' : 'high'}
      decoding={lazy ? 'async' : undefined}
      className={`${className}`}
    />
  );
}

FixedImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  subfolder: PropTypes.string,
  folder: PropTypes.string,
  lazy: PropTypes.bool,
};

FixedImg.defaultProps = {
  size: {},
  alt: '',
  className: '',
  width: '',
  folder: 'product',
  subfolder: '',
  lazy: true,
};

export default FixedImg;
