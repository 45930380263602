import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SpecialFormattedNumber from 'Containers/Utility/Price/SpecialFormattedNumber';

const NavCartButton = ({ showPrice, parentClick, cartCalc }) => {
  const { items, total } = cartCalc;
  return (
    <Link
      to="/cart"
      className="nav-cart-btn d-flex align-items-center"
      aria-label="Cart"
      onClick={() => { parentClick(); }}
    >
      <FontAwesomeIcon fixedWidth icon="shopping-cart" className="mr-1" />
      <div className={`nav-badge nav-badge-${items.length}`}>
        {items.length}
      </div>

      <span
        className={
          classNames(
            { 'd-inline-block': showPrice },
            { 'd-none': !showPrice },
            'nav-cart-total',
          )
        }
      >
        <SpecialFormattedNumber
          value={total}
          style="currency" // eslint-disable-line react/style-prop-object
        />
      </span>
    </Link>
  );
};

NavCartButton.propTypes = {
  showPrice: PropTypes.bool,
  cartCalc: PropTypes.shape({}),
  parentClick: PropTypes.func,
};

NavCartButton.defaultProps = {
  showPrice: false,
  cartCalc: {
    total: 0,
    items: [],
  },
  parentClick: () => {},
};

export default NavCartButton;
