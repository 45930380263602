import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { logout } from 'Redux/ducks/auth';
import { history } from 'Redux/store';

function Logout({
  authenticated,
  router,
  logout: doLogout,
  parentClick: doParentClick,
  tag: Tag,
  children,
  ...attributes
}) {
  if (!authenticated) return false;
  const handleClick = () => {
    if (doParentClick) {
      doParentClick();
    }
    const pathname = get(router, 'location.pathname', '/');
    // account page relies on going to app main route on logout
    if (pathname === '/account' || pathname === '/unsubscribe') {
      history.push('/');
    }
    doLogout();
  };
  return <Tag onClick={() => handleClick()} {...attributes}>{children}</Tag>;
}

Logout.propTypes = {
  authenticated: PropTypes.bool,
  tag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  logout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  parentClick: PropTypes.func,
  router: PropTypes.shape({}),
};

Logout.defaultProps = {
  authenticated: false,
  tag: 'button',
  parentClick: () => {},
  router: {},
};

export default connect(({ auth: { authenticated }, router }) =>
  ({ authenticated, router }), { logout })(Logout);
