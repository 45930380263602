import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErrorBoundary from 'Components/Utility/ErrorBoundary';

const CookieConsent = lazy(() => import(/* webpackChunkName: "BiscuitAuthorization" */ 'Components/Cookie/CookieConsent'));

function CookieConsentWrapper({ open, consent, isPolicyPage, siteBrand }) {
  if (siteBrand === 'hpomen') return false;

  if (open) {
    return (
      <ErrorBoundary hideForm noChunkRedirect>
        <Suspense fallback={<div />}>
          <CookieConsent open={open} isPolicyPage={isPolicyPage} consent={consent} />
        </Suspense>
      </ErrorBoundary>
    );
  }

  return false;
}

CookieConsentWrapper.propTypes = {
  open: PropTypes.bool,
  isPolicyPage: PropTypes.bool,
  consent: PropTypes.shape({}),
};

CookieConsentWrapper.defaultProps = {
  open: false,
  isPolicyPage: false,
  consent: {},
};

const mapStateToProps = ({
  cookie: { open, consent },
  router: { location: { pathname } },
  siteBrand,
}) => ({
  open,
  consent,
  isPolicyPage: pathname === '/cookie-policy',
  siteBrand,
});

export default connect(mapStateToProps, {})(CookieConsentWrapper);
