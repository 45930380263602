import { nativeCurrencyCode, currencyCode, nativeCurrencyName, currencyName } from 'Helpers/Geo/data';


/**
 * Check if user has a native currency selected
 * @return {Boolean}
 */
const checkHasNativePrice = () => nativeCurrencyCode && nativeCurrencyCode !== currencyCode;

/**
   * Find available native currency from country data
   * @return {Object|False}
   */
const getNativeCurrency = () => {
  const hasNativePrice = checkHasNativePrice();

  if (hasNativePrice) {
    return { hasNativePrice, nativeCurrencyCode, nativeCurrencyName };
  }

  return false;
};

const getDefaultCurrency = () => ({
  currencyCode,
  currencyName,
});

/**
   * Does user have a selected language in local storage
   * @return {Boolean}
   */
const hasStoredLanguage = () => {
  const localLanguage = localStorage.getItem('language');
  return !!localLanguage && JSON.parse(localLanguage) && JSON.parse(localLanguage).code;
};

/**
   * User's selected currency from local storage
   * @return {String|False} [description]
   */
const getStoredCurrency = () => localStorage.getItem('displayCurrency') || false;

/**
   * Does the user have the native currency selected
   * @return {Boolean}
   */
const isCurrencySelected = () => getNativeCurrency() &&
    getNativeCurrency().nativeCurrencyCode === getStoredCurrency();


export {
  getNativeCurrency,
  getDefaultCurrency,
  hasStoredLanguage,
  isCurrencySelected,
  getStoredCurrency,
  checkHasNativePrice,
};
