import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SlimBanner from 'Components/Banner/SlimBanner';

const SlimBannerContainer = ({ adverts, location }) => {
  const slimBanners = get(adverts, 'SlimBanner', []);
  const takeOverBanners = get(adverts, 'TakeOver', []);

  if (slimBanners.length === 0) return false;
  const slimBanner = get(slimBanners, '[0]', {});
  if (!slimBanner) return false;

  const allowedPathNames = ['search', 'game', 'dlc', 'book', 'comic', 'audio', 'video', 'software'];
  const pathname = get(location, 'pathname', '').split('/')[1];
  const slug = get(location, 'pathname', '').split('/')[2];
  const isOnProductOrSearchPage = allowedPathNames.includes(pathname) || (pathname === 'bundle' && slug);

  if (!isOnProductOrSearchPage) return false;

  // Don't show slim banner when a takeover is live
  let takeOverOnBundlePage = false;
  takeOverBanners.forEach((banner) => {
    if (banner.appears_on && banner.appears_on.bundles) takeOverOnBundlePage = true;
  });

  if (takeOverOnBundlePage && pathname === 'bundle' && (slug === 'games' || slug === 'books' || slug === 'software')) {
    return false;
  }

  const externalLink = get(slimBanner, 'external_link', false);
  const url = get(slimBanner, 'url');
  const images = get(slimBanner, 'images', []);
  const logo = images.find(image => get(image, 'img_type') === 'slim_logo');
  const background = images.find(image => get(image, 'img_type') === 'slim_background');
  const sponsor = images.find(image => get(image, 'img_type') === 'slim_sponsor');

  if (!logo) return false;

  const logoSrc = get(logo, 'img', '');
  const backgroundSrc = get(background, 'img', '');
  const sponsorSrc = get(sponsor, 'img', '');
  const slimBackgroundColor = get(slimBanner, 'slim_background_colour', '#FFFFFF');
  const slimButtonColor = get(slimBanner, 'slim_button_data.text_colour', '#000000');
  const slimButtonBackgroundColor = get(slimBanner, 'slim_button_data.background_colour', '#FFFFFF');

  const returnBanner = () =>
    <SlimBanner
      logoSrc={logoSrc}
      backgroundSrc={backgroundSrc}
      sponsorSrc={sponsorSrc}
      slimButtonColor={slimButtonColor}
      slimButtonBackgroundColor={slimButtonBackgroundColor}
      slimBackgroundColor={slimBackgroundColor}
    />;

  return (
    <React.Fragment>
      {!url &&
        returnBanner()
      }
      {url &&
        <React.Fragment>
          {!externalLink &&
            <Link to={`/${url.split('/en/')[1]}`}>
              {returnBanner()}
            </Link>
          }
          {externalLink &&
            <a href={url} target="_blank" rel="noopener noreferrer">
              {returnBanner()}
            </a>
          }
        </React.Fragment>
        }
    </React.Fragment>
  );
};

SlimBannerContainer.propTypes = {
  adverts: PropTypes.shape({}),
};

SlimBannerContainer.defaultProps = {
  adverts: {},
};

const mapStateToProps = ({ adverts, router: { location } }) => ({ adverts, location });
export default connect(mapStateToProps)(SlimBannerContainer);
