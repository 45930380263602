import { useState, useEffect, useRef } from 'react';

/**
 * useSize Hook - Get the size of an element
 * @returns {[object, object]} ref, size
 */

const useSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        setSize({ width, height });
      });
    });

    if (element) {
      resizeObserver.observe(element);
    }


    return () => {
      if (element) {
        resizeObserver.unobserve(element);
        resizeObserver.disconnect();
      }
    };
  }, []);

  return [ref, size];
};

export default useSize;
