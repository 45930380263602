import React, { useEffect } from 'react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import env from 'Env';
import App from 'Components/App/App';
import store from 'Redux/store';
import GeneralUtils from 'Helpers/GeneralUtils';
import getUrlParameter from 'Helpers/Url/GetUrlParameter';
import { exitIntentFandom, exitIntentEmailCart } from 'Redux/ducks/exit-intent';
import { doGoogleLogin, formatGoogleOneTapCreditionals } from 'Redux/ducks/google';
import { openLoginModal } from 'Redux/ducks/modal';
import { switchSiteTheme } from 'Redux/ducks/site-theme';
import { switchSiteBrand } from 'Redux/ducks/site-brand';
import isBot from 'Helpers/SEO/is-bot';
import nrWrap from 'Helpers/newrelic';
import waitForSentry from 'Helpers/Sentry/wait-for-sentry';


// Usually the reason on PromiseRejectionEvent is not an error, so Sentry cannot handle
// it. This class converts the object back into an error that Sentry can process
// https://sentry.zendesk.com/hc/en-us/articles/26649121915291-I-m-getting-Object-captured-as-exception-with-keys
class UnhandledRejectionError extends Error {
  constructor(reason) {
    super(reason.message);
    this.name = reason.name || this.constructor.name;
    this.message = reason.message;
    this.stack = reason.stack || reason.transporterStackTrace || new Error().stack;
  }
}

export default function AppInitializer() {
  useEffect(() => {
    nrWrap((newrelic) => {
      newrelic.setCustomAttribute('language', window.locale);
      if (window.version) {
        newrelic.setApplicationVersion(window.version.toString());
      }
    });
    window.addEventListener('unhandledrejection', (err) => {
      if (err.reason) {
        if (err.reason.message && err.reason.message.match(/Loading chunk (.*) failed|promise.all is not|promise.resolve is not/)) {
          let url = window.location.href;

          if (!url.includes('chunkRedirect=1')) {
            url = `${url}${url.includes('?') ? '&' : '?'}chunkRedirect=1`;
            window.location.href = url;

            return false;
          }
        }

        if (err.reason.message && err.reason.message === '"validUntil" parameter expired (less than current date)') {
          let url = window.location.href;

          if (!url.includes('algoliaKeyExpired=1')) {
            url = `${url}${url.includes('?') ? '&' : '?'}algoliaKeyExpired=1`;
            window.location.href = url;

            return false;
          }
        }

        if (err.reason.message && err.reason.message.match(/QuotaExceededError/)) {
          let url = window.location.href;

          if (!url.includes('QuotaExceededError=1')) {
            url = `${url}${url.includes('?') ? '&' : '?'}QuotaExceededError=1`;
            localStorage.removeItem('recentlyViewed');
            localStorage.removeItem('bsvoucher');
            localStorage.removeItem('MOE_DATA');
            localStorage.removeItem('anonWishlistSlugs');
            localStorage.removeItem('dismissedNotifications');
            localStorage.removeItem('persistedUserReview');
            window.location.href = url;

            return false;
          }
        }
        if (err.reason.message) {
          waitForSentry((Sentry) => {
            if (err.reason instanceof Error) {
              return Sentry.captureException(err.reason);
            }

            // See comments above for why we do this
            const error = new UnhandledRejectionError(err.reason);
            return Sentry.captureException(error);
          });
        }
      }
      return false;
    });

    if (!isBot()) {
      let honeyInterval;

      const checkHoney = () => {
        const honey = document.querySelector('#honeyContainer');
        if (honey) {
          store.dispatch({ type: 'frontend/auth/DETECT_HONEY', payload: { hasHoney: true } });
          clearInterval(honeyInterval);
        }
      };

      honeyInterval = setInterval(checkHoney, 4000);

      const updateExitIntentState = (value) => {
        const reduxStore = store.getState();
        const intentRedux = reduxStore.exitIntentState.leftBrowserStarDeal;
        const noliveStarDeal = reduxStore.starDeal === null;
        const currentPage = reduxStore.router.location.pathname;
        const stardealSlug = get(reduxStore, 'starDeal.slug');
        let pageException = false;
        if (includes(currentPage, '/orders') || includes(currentPage, '/account') || includes(currentPage, stardealSlug)) {
          pageException = true;
        }

        if (value !== intentRedux && !noliveStarDeal && !pageException) {
          // store.dispatch(exitIntentStarDeal(value)); // may return for future sales
        }
      };

      /* eslint-disable arrow-parens */
      document.addEventListener('mouseout', evt => {
        if (evt.toElement !== null && evt.relatedTarget !== null) {
          updateExitIntentState(false);
        }
      });

      document.addEventListener('mouseout', evt => {
        const reduxStore = store.getState();
        const noliveStarDeal = reduxStore.starDeal === null;
        const emailCartIntent = reduxStore.exitIntentState || false;
        const cartContents = reduxStore.cart || {};

        if (
          (evt.toElement === null || evt.toElement === undefined) && // undefined needed for FireFox
          evt.relatedTarget === null &&
          evt.pageY - window.pageYOffset < 10
        ) {
          const exitIntentStarDealLS = localStorage.getItem('exitIntentStarDeal');
          const lastSeenStarDeal = localStorage.getItem('lastSeenStarDeal');
          const exitIntentEmailCartLS = localStorage.getItem('exitIntentEmailCart');
          const exitIntentFandomLS = localStorage.getItem('exitIntentFandom');
          const exitIntentFandomAnswerLS = localStorage.getItem('answeredFandomExitIntent');
          const platform = get(window, 'navigator.platform');
          const newUser = GeneralUtils.newUser();
          const urlParam = getUrlParameter('utm_source');
          // const twentyFourHours = 5000; // 5 Seconds
          const twentyFourHours = 86400000;
          const oneWeek = twentyFourHours * 7;
          const currentPage = reduxStore.router.location.pathname;

          if (
            window.locale === 'en' &&
            !platform.includes('Linux') &&
            newUser &&
            urlParam.toLowerCase() === 'fandom' &&
            !exitIntentFandomAnswerLS &&
            (!exitIntentFandomLS ||
              (exitIntentFandomLS && (Date.now() - exitIntentFandomLS) > twentyFourHours)
            )
          ) {
            store.dispatch(exitIntentFandom(true));
            localStorage.setItem('exitIntentFandom', Date.now());
          }

          let popupSeenOverTwentyFourHoursAgo = false;
          if (exitIntentStarDealLS && (Date.now() - exitIntentStarDealLS) > twentyFourHours) {
            popupSeenOverTwentyFourHoursAgo = true;
          }

          let starDealSeenWithinOneWeek = false;
          if (lastSeenStarDeal && (Date.now() - lastSeenStarDeal) < oneWeek) {
            starDealSeenWithinOneWeek = true;
          }

          // If there is nothing in local storage or popup was seen over 24 hours ago and
          // user has seen a star deal within 1 week, show popup
          let starDealCriteria = false;
          if (
            (!exitIntentStarDealLS && lastSeenStarDeal) ||
            (popupSeenOverTwentyFourHoursAgo && starDealSeenWithinOneWeek)
          ) {
            starDealCriteria = true;
            updateExitIntentState(true);
            if (!noliveStarDeal) localStorage.setItem('exitIntentStarDeal', Date.now());
          }

          let showExitIntentEmailCart = false;
          if ((Date.now() + twentyFourHours) - exitIntentEmailCartLS < twentyFourHours &&
            !reduxStore.auth.authenticated) {
            showExitIntentEmailCart = true;
          }

          // Stardeal intent has already been shown & cart has items
          if ((!starDealCriteria && !isEmpty(cartContents.items) &&
            (showExitIntentEmailCart || !exitIntentEmailCartLS)) &&
            !reduxStore.auth.authenticated) {
            if (!emailCartIntent.leftBrowserEmailCart && window.locale === 'en' && includes(currentPage, '/cart')) {
              store.dispatch(exitIntentEmailCart(true));
              localStorage.setItem('exitIntentEmailCart', Date.now());
            }
          }
        }
      });
    }

    const $root = document.getElementById('root');
    const $skeleton = document.getElementById('skeleton');

    $root.style.display = 'block';

    if ($skeleton) {
      $skeleton.innerHTML = '';
    }

    if (!isBot()) {
      const googleTapCallBack = async (response) => {
        const googleObject = formatGoogleOneTapCreditionals(response.credential);
        await store.dispatch(doGoogleLogin(googleObject));
        const state = store.getState();
        const subscribed = get(state, 'auth.email_newsletter');
        const challenge = get(state, 'auth.challenge.type');

        if (challenge === 'twoFactor') {
          store.dispatch(openLoginModal());
        }

        store.dispatch({ type: 'frontend/auth/AUTH_LOG', payload: { action: 'Google one tap - sign in' } });

        localStorage.setItem('isGoogleOneTapUser', true);

        if (!subscribed && challenge !== 'twoFactor') {
          store.dispatch(openLoginModal('newsletter'));
        }
      };

      if (!window.google || !window.google.accounts) {
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://accounts.google.com/gsi/client';
        scriptTag.async = true;
        scriptTag.defer = true;
        scriptTag.addEventListener('load', () => {
          if (typeof google !== 'undefined' && google.accounts) {
            google.accounts.id.initialize({
              client_id: env.gcp.authClientId,
              cancel_on_tap_outside: false,
              callback: googleTapCallBack,
              prompt_parent_id: 'g_id_onload',
            });
          }
        });
        document.body.appendChild(scriptTag);
      }
    }

    // CO-BRANDING
    // If a branding partner is present, the brand and theme can be changed
    if (!isBot()) {
      const brandingPartner = getUrlParameter('partnerBrand');
      if (brandingPartner) {
        if (brandingPartner !== 'fanatical') {
          localStorage.setItem('lastSeenCoBranding', Date.now());
        }

        if (brandingPartner === 'acer') {
          switchSiteBrand('acer');
          switchSiteTheme('light');
        }
        if (brandingPartner === 'gamespot') {
          switchSiteBrand('gamespot');
          switchSiteTheme('light');
        }
        if (brandingPartner === 'box') {
          switchSiteBrand('box');
          switchSiteTheme('light');
        }
        if (brandingPartner === 'chilliblast') {
          switchSiteBrand('chilliblast');
          switchSiteTheme('dark');
        }
        if (brandingPartner === 'razer') {
          switchSiteBrand('razer');
          switchSiteTheme('dark');
        }
        if (brandingPartner === 'powercolor') {
          switchSiteBrand('powercolor');
          switchSiteTheme('dark');
        }
        if (brandingPartner === 'ccl') {
          switchSiteBrand('ccl');
          switchSiteTheme('light');
        }
        if (brandingPartner === 'insidetech') {
          switchSiteBrand('insidetech');
          switchSiteTheme('light');
        }
        if (brandingPartner === 'stormforcegaming') {
          switchSiteBrand('stormforce');
          switchSiteTheme('dark');
        }
        if (brandingPartner === 'hpomen') {
          switchSiteBrand('hpomen');
          switchSiteTheme('dark');
        }
        if (brandingPartner === 'bundlestars') {
          switchSiteBrand('bundlestars');
          switchSiteTheme('dark');
        }
        if (brandingPartner === 'fanatical') {
          switchSiteBrand('fanatical');
        }
      }

      // const twentyFourHours = 5000; // 5 Seconds
      const twentyFourHours = 86400000;
      const threeDays = twentyFourHours * 3;

      const lastSeenCoBrandingLS = localStorage.getItem('lastSeenCoBranding');
      if (lastSeenCoBrandingLS && (Date.now() - lastSeenCoBrandingLS) > threeDays) {
        switchSiteBrand('fanatical');
      }
    }
  }, []);

  return <App />;
}
