/**
 * Wrapper for checking Sentry is attached to window before calling back with window.Sentry
 * Callback is not called if window.Sentry is not called within 10 attempts
 * @param  {Function} callback
 * @return {Sentry}
 */
export default function waitForSentry(callback) {
  let attempts = 0;

  if (window.Sentry) {
    return callback(window.Sentry);
  }

  const interval = setInterval(() => {
    attempts += 1;
    if (window.Sentry) {
      callback(window.Sentry);
      clearInterval(interval);
    } else if (attempts > 10) {
      console.warn('window.Sentry not found'); // eslint-disable-line no-console
      clearInterval(interval);
    }
  }, 500);
  return null;
}
