import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

import store from 'Redux/store';
import { removeFeedbackPopup } from 'Redux/ducks/feedback-popups';

function FeedbackPopup({ feedback, intl }) {
  const [closing, setClosing] = useState(false);
  const [closingPause, setClosingPause] = useState(false);

  // Store timeout so it can be cleared by close function. Will close after 5 seconds
  const delayedRemove = useCallback(() => {
    setTimeout(() => {
      store.dispatch(removeFeedbackPopup(feedback.id));
    }, 5000);
  }, [feedback]);

  useEffect(() => {
    if (feedback.display !== 'persist') {
      // setClosingPause true adds the fade-out-pause class animation
      setClosingPause(true);
      delayedRemove();
    }
  }, [feedback, delayedRemove]);

  const closeFeedback = () => {
    // Clear the delayedRemove timeout
    clearTimeout(delayedRemove);

    setClosing(true);
    setClosingPause(false);
    setTimeout(() => {
      store.dispatch(removeFeedbackPopup(feedback.id));
    }, 1000);
  };

  const messages = defineMessages({
    add_voucher_success_title: {
      id: 'feedback_popup.add_voucher.success_title',
      defaultMessage: 'Your coupon has applied successfully!',
    },
    add_voucher_error_title: {
      id: 'feedback_popup.add_voucher.error_title',
      defaultMessage: 'Sorry, your coupon could not be applied',
    },
    add_voucher_error_message: {
      id: 'feedback_popup.add_voucher.error_message',
      defaultMessage: 'The coupon code may be invalid, expired or not yet active',
    },
    delete_account_message: {
      id: 'feedback_popup.delete_account_message.success_message',
      defaultMessage: 'Account deleted',
    },
  });

  const { formatMessage } = intl;

  let title = feedback.title;
  let message = feedback.message;
  if (title === 'coupon-success') {
    title = formatMessage(messages.add_voucher_success_title);
  }
  if (title === 'coupon-error') {
    title = formatMessage(messages.add_voucher_error_title);
  }
  if (message === 'coupon-error') {
    message = formatMessage(messages.add_voucher_error_message);
  }
  if (title === 'delete-success') {
    title = formatMessage(messages.delete_account_message);
  }

  return (
    <div
      id={feedback.id}
      className={classNames(`feedback-popup ${feedback.type}`, { 'fade-out': closing }, { 'fade-out-pause': closingPause })}
      role="alert"
      aria-live="assertive"
    >
      <div className="feedback-header">
        <div className="icon-title-container">
          {(feedback.type === 'success' || feedback.type === 'error' || feedback.type === 'info' || feedback.type === 'warning') &&
            <div className="icon-background">
              {feedback.type === 'success' &&
                <FontAwesomeIcon icon="check" />
              }
              {feedback.type === 'error' &&
                <FontAwesomeIcon icon="exclamation" />
              }
              {feedback.type === 'info' &&
                <FontAwesomeIcon icon="info" />
              }
              {feedback.type === 'warning' &&
                <FontAwesomeIcon icon="exclamation-triangle" />
              }
            </div>
          }
          {title}
        </div>
        <div className="close-button-container">
          <button aria-label="Close notification" className="btn" onClick={() => closeFeedback()}>
            <FontAwesomeIcon icon="times" title="Close notification" />
          </button>
        </div>
      </div>
      <div className="feedback-body">
        {message}
      </div>
    </div>
  );
}

FeedbackPopup.propTypes = {
  feedback: PropTypes.shape({}),
};

FeedbackPopup.defaultProps = {
  feedback: {},
};

export default injectIntl(FeedbackPopup);
