import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CarouselArrow(props) {
  const {
    ariaLabel,
    arrowDirection,
    className,
    containerClassName,
    onClick,
    onHover,
  } = props;

  return (
    <div className={`${containerClassName} btn-${arrowDirection}`}>
      <button
        className={className}
        aria-label={ariaLabel}
        onClick={onClick}
        onMouseEnter={() => { onHover(); }}
      >
        <FontAwesomeIcon icon={`chevron-${arrowDirection}`} />
      </button>
    </div>
  );
}

CarouselArrow.propTypes = {
  ariaLabel: PropTypes.string,
  arrowDirection: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

CarouselArrow.defaultProps = {
  ariaLabel: 'Next',
  arrowDirection: 'right',
  className: '',
  containerClassName: 'btn-container',
  onClick: () => {},
  onHover: () => {},
};

export default CarouselArrow;
