/**
 * Replacement for component from ReactStrap, removing popper dependency
 */

import React from 'react';
import PropTypes from 'prop-types';
import { DropdownContext } from 'reactstrap';
import classNames from 'classnames';

export default function DropdownMenu(props) {
  return (
    <DropdownContext.Consumer>
      {({ isOpen }) => (
        <div className={classNames('dropdown-menu', { show: isOpen, 'dropdown-right': !!props.right || !!props.end })}>
          {props.children}
        </div>
      )}
    </DropdownContext.Consumer>
  );
}

DropdownMenu.propTypes = {
  children: PropTypes.node,
};

DropdownMenu.defaultProps = {
  children: <React.Fragment />,
};
