import React from 'react';
import PropTypes from 'prop-types';

const CustomPaging = ({ slideIndex, slideLength, slidesToShow, ...props }) => {
  const validSlideLength = Number.isNaN(Number(slideLength)) ? 0 : Number(slideLength);

  const totalDots = Math.ceil(validSlideLength / slidesToShow);
  const validSlideIndex = Math.max(0, Math.min(slideIndex, totalDots - 1));


  return (
    <div
      {...props}
      tabIndex={0}
      role="button"
      aria-label={`Go to slide ${validSlideIndex + 1} of ${totalDots}`}
      className={props.className || 'mobile-friendly-dots'}
    />
  );
};

CustomPaging.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  slidesToShow: PropTypes.number.isRequired,
  slideLength: PropTypes.number.isRequired,
};

CustomPaging.defaultProps = {
  slideIndex: 0,
  slideLength: 0,
  slidesToShow: 1,
};

export default CustomPaging;
