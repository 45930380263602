import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import { addToCart, doAddToCart } from 'Redux/ducks/cart';
import { addVoucher } from 'Redux/ducks/voucher';
import store, { history } from 'Redux/store';

function AddToCartBtn(props) {
  const [addingToBasket, setAddingToBasket] = useState(false);

  const {
    allSlugs,
    buttonInCartText,
    buttonText,
    goToCart,
    hideButtonText,
    id,
    modalToggle,
    place,
    quickLook,
    regionLocked,
    showInCartLink,
    slug,
    voucherToAdd,
    hideIcon,
    tierIndex,
    newIcon,
    parentComponent,
    onAddToCart,
  } = props;

  const inCart = allSlugs.includes(slug);

  if (inCart && showInCartLink) {
    return (
      <Link
        to="/cart"
        className={classNames('AddToCartBtn btn btn-primary', { 'AddToCartBtn--iconOnly': hideButtonText }, { 'd-none d-lg-flex': parentComponent !== 'WishlistCard' })}
        onClick={modalToggle ? () => modalToggle() : () => { }}
      >
        {!hideIcon &&
          <div className="cart-btn-icon">
            <FontAwesomeIcon icon="check" className="mr-1" title="In Cart" />
          </div>
        }
        {!hideButtonText &&
          <div className="btn-text-container in-cart-text">
            {!buttonInCartText &&
              <FormattedMessage id="hitcardbuttons.incart" defaultMessage="In Cart" />
            }
            {buttonInCartText &&
              <React.Fragment>
                {buttonInCartText}
              </React.Fragment>
            }
          </div>
        }
      </Link>
    );
  }

  if (inCart) {
    return false;
  }

  const { push } = history;

  const clickAddToCart = async () => {
    setAddingToBasket(true);
    if (quickLook && modalToggle) {
      modalToggle();
    }
    if (voucherToAdd) {
      await props.addVoucher(voucherToAdd);
    }
    if (regionLocked && slug) {
      await props.doAddToCart(push, null, tierIndex, 'popup', slug);
    } else {
      await props.addToCart(push, id, tierIndex, 'popup');
    }

    if (place === 'Checkout Modal') {
      store.dispatch({ type: 'frontend/checkout-modal/ADDED_TO_CART_FROM_CHECKOUT_MODAL', payload: { product: id } });
    }

    setAddingToBasket(false);

    if (onAddToCart) {
      onAddToCart();
    }

    if (goToCart) {
      window.location.href = '/cart';
    }
  };

  return (
    <Button
      color="primary"
      disabled={addingToBasket}
      className={classNames('AddToCartBtn', { 'AddToCartBtn--iconOnly': hideButtonText }, { 'd-none d-lg-flex': parentComponent !== 'WishlistCard' })}
      onClick={() => clickAddToCart()}
    >
      {!hideIcon &&
        <div className="cart-btn-icon">
          {newIcon ?
            <FontAwesomeIcon icon="cart-plus" className="mr-1" title="Add To Cart" />
            :
            <FontAwesomeIcon icon="shopping-cart" className="mr-1" title="Add To Cart" />
          }
        </div>
      }
      {!addingToBasket && !hideButtonText &&
        <div className="btn-text-container">
          {!buttonText &&
            <div>
              <FormattedMessage id="button.addtocart" defaultMessage="Add" />
            </div>
          }
          {buttonText &&
            <div>
              {buttonText}
            </div>
          }
        </div>
      }

      {addingToBasket &&
        <div className="btn-text-container">
          <FontAwesomeIcon
            icon="spinner"
            size="lg"
            className="fa-spin"
            title="Loading"
          />
        </div>
      }
    </Button>
  );
}

AddToCartBtn.propTypes = {
  addToCart: PropTypes.func,
  addVoucher: PropTypes.func,
  allSlugs: PropTypes.arrayOf(PropTypes.string),
  buttonInCartText: PropTypes.string,
  buttonText: PropTypes.string,
  doAddToCart: PropTypes.func,
  goToCart: PropTypes.bool,
  hideButtonText: PropTypes.bool,
  id: PropTypes.string.isRequired,
  modalToggle: PropTypes.func,
  place: PropTypes.string,
  quickLook: PropTypes.bool,
  regionLocked: PropTypes.bool,
  showInCartLink: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  voucherToAdd: PropTypes.string,
  hideIcon: PropTypes.bool,
  newIcon: PropTypes.bool,
  tierIndex: PropTypes.number,
  parentComponent: PropTypes.string,
  onAddToCart: PropTypes.func,
};

AddToCartBtn.defaultProps = {
  addToCart: () => { },
  addVoucher: () => { },
  allSlugs: [],
  buttonInCartText: null,
  buttonText: null,
  doAddToCart: () => { },
  goToCart: false,
  hideButtonText: false,
  modalToggle: () => { },
  place: '',
  quickLook: false,
  regionLocked: false,
  showInCartLink: false,
  voucherToAdd: null,
  hideIcon: false,
  newIcon: false,
  tierIndex: null,
  parentComponent: undefined,
  onAddToCart: undefined,
};


export default connect(
  ({ cart: { allSlugs } }) => ({ allSlugs }),
  { addToCart, doAddToCart, addVoucher })(AddToCartBtn);
