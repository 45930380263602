import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import round from 'lodash/round';

const displayStarScore = (starScore) => {
  const outOfStarScoreArray = [...Array(5).keys()];
  const showHalfStar = starScore % 1 >= 0.5;

  const stars = outOfStarScoreArray.map((score, index) =>
    <div className={classNames('star', { 'highlighted-star': (index + 1) <= starScore })} key={score} role="img" aria-label={(index + 1) === round(starScore) && showHalfStar ? 'Half star' : 'Full star'}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" preserveAspectRatio="xMinYMid meet" role="img">
        <polygon points="45 0 58.91 29.63 90 34.38 67.5 57.44 72.81 90 45 74.63 17.19 90 22.5 57.44 0 34.38 31.09 29.63 45 0" />
      </svg>
      {(index + 1) === round(starScore) && showHalfStar &&
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 90" preserveAspectRatio="xMinYMid meet" className="star-half-icon" role="img">
          <polyline points="45 74.63 17.19 90 22.5 57.44 0 34.38 31.09 29.63 45 0" />
        </svg>
      }
    </div>,
  );

  return stars;
};

const StarRating = ({
  rating,
  lightTheme,
}) =>
  <div className={classNames('star-rating-container', { 'stars-light-theme': lightTheme })}>
    {displayStarScore(rating.toFixed(1))}
  </div>;

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  lightTheme: PropTypes.bool,
};

StarRating.defaultProps = {
  rating: 0,
  lightTheme: false,
};

export default StarRating;
