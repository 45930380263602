
/**
 * Build object for the `to` prop on Link components
 * @param  {Object} hit     Algolia Hit object
 * @return {String|Object}  Link or pathname and state object
 */
export default (hit) => {
  if (!hit) {
    return null;
  }
  if (hit.link && typeof hit.link === 'string') {
    return hit.link;
  }

  const pathname = `/${hit.type}/${hit.slug}`;

  if (window.location.pathname.includes('gift-guide')) {
    return {
      pathname,
      state: { from: window.location.pathname },
    };
  }

  return {
    pathname,
  };
};
