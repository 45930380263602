import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { onCLS, onINP, onLCP, onTTFB, onFCP } from 'web-vitals';

import 'Helpers/Sentry/sentry-load';
import PersistStore from 'Components/App/PersistStore';
import store from 'Redux/store';
import { setServerSideCookie } from 'Redux/ducks/cookie';
import { getByCode, getAll } from 'Config/lang';
import 'Styles/bootstrap.scss';
import 'Styles/index.scss';
import AppInitializer from 'Src/AppInitializer';
import getUrlParameter from 'Helpers/Url/GetUrlParameter';
import 'Helpers/LocalStorage';
import waitForSentry from 'Helpers/Sentry/wait-for-sentry';

window.setServerSideCookie = setServerSideCookie;

const isGoogleTranslated = !!document.querySelector('html').className.match('translated');

if (window.location.hostname === 'frontend.fanatical.com') {
  window.location.hostname = 'www.fanatical.com';
  throw new Error('Direct access to \'frontend.fanatical.com\' not allowed');
}

if (window.location.hostname === 'frontend-prelive.fanatical.com') {
  window.location.hostname = 'prelive.fanatical.com';
  throw new Error('Direct access to \'frontend-prelive.fanatical.com\' not allowed');
}

// On google translate proxied links, redirect to relevant page/language
if (window.location.href.includes('translate.googleusercontent.com')) {
  const lang = getByCode(getUrlParameter('tl')) ? getByCode(getUrlParameter('tl')).code : 'en';
  if (getUrlParameter('u')) {
    const path = getUrlParameter('u').replace('https://www.fanatical.com/en/', '');
    window.location.href = `https://www.fanatical.com/${lang}/${path}`;
    throw new Error('Redirecting from google translate to local language');
  }

  window.location.href = `https://www.fanatical.com/${lang}`;
  throw new Error('Redirecting from google translate to english language with no page');
}

if (window.location.href.includes('featured-carousel')) {
  localStorage.removeItem('language');
}

if (localStorage.getItem('language')) {
  let localStorageLanguage = localStorage.getItem('language');
  const languages = getAll() || [];
  if (
    localStorageLanguage &&
    !languages.find(language => language.code === JSON.parse(localStorageLanguage).code)
  ) {
    localStorageLanguage = '{"code":"en","label":"English","nativeLabel":"English","default":true}';
    localStorage.setItem('language', localStorageLanguage);
  }
  const savedLocale = JSON.parse(localStorageLanguage).code;
  const urlLocale = window.locale;
  const pathname = window.location.pathname.replace(`/${urlLocale}`, '');
  if (savedLocale && getByCode(savedLocale) && urlLocale && savedLocale !== urlLocale && !window.location.search.includes('langRedirect') && !window.location.href.includes('featured-carousel')) {
    const referer = document.referrer;
    const qs = `${window.location.search ? `${window.location.search}&` : '?'}langRedirect=${urlLocale}${referer ? `&langRedirectRef=${btoa(referer).replace(/=/g, '')}` : ''}`;

    window.location.href = `${window.location.origin}/${savedLocale}${pathname}${qs}`;
    throw new Error('Redirecting to local language');
  }
}

let googleTranslateFixAdded = false;

if (isGoogleTranslated) {
  document.addEventListener('DOMSubtreeModified', (e) => {
    if (e.target.tagName === 'HTML' && !googleTranslateFixAdded) {
      if (e.target.className.match('translated')) {
        googleTranslateFixAdded = true;
        /* eslint-disable */
        if (typeof Node === 'function' && Node.prototype) {
          const originalRemoveChild = Node.prototype.removeChild;
          Node.prototype.removeChild = function(child) {
            if (child.parentNode !== this) {
              if (console) {
                console.error('Cannot remove a child from a different parent', child, this);
              }
              return child;
            }
            return originalRemoveChild.apply(this, arguments);
          }

          const originalInsertBefore = Node.prototype.insertBefore;
          Node.prototype.insertBefore = function(newNode, referenceNode) {
            if (referenceNode && referenceNode.parentNode !== this) {
              if (console) {
                console.error('Cannot insert before a reference node', referenceNode, this);
              }
              return newNode;
            }
            return originalInsertBefore.apply(this, arguments);
          }
        }
        /* eslint-enable */
      }
    }
  }, true);
}


try {
  localStorage.setItem('localStorageTest', 'test');
  if (localStorage.getItem('localStorageTest') !== 'test') {
    throw new Error('Value doesn\'t match');
  }
  localStorage.removeItem('localStorageTest');

  if (window.location.href.includes('reset-basket')) {
    // Delete anonid, for support to send to people with basket issues
    localStorage.removeItem('bsanonymous');
  }
} catch (ex) {
  waitForSentry((Sentry) => {
    Sentry.captureException(new Error('Local Storage not available'));
  });
}

if ('ontouchstart' in window || 'ontouch' in window) {
  window.touchEnabled = true;
} else {
  window.touchEnabled = false;
}

// IE11 Polyfill issue https://github.com/Financial-Times/polyfill-library/issues/163#issuecomment-509689271
// Object.prototype[Symbol.toStringTag] = Object.prototype[Symbol.toStringTag];

const $root = document.getElementById('root');

hydrateRoot(
  $root,
  <PersistStore>
    <Provider store={store}>
      <AppInitializer />
    </Provider>
  </PersistStore>,
);

setTimeout(() => {
  try {
    import(/* webpackChunkName: "FrozenWaterScrape" */ 'Src/snowplow'); // eslint-disable-line no-unused-expressions
  } catch (e) {
    console.error('sp import failed', e); // eslint-disable-line no-console
  }
}, 500);

// Conditionally show the moengage soft popup based on split test.
// Only trigger split test when moengage would show the popup.
// CSS rule hides #moe-push-div by default, then displays with d-block on Control side of split test
window.addEventListener('MOE_OPT_IN', (e) => {
  if (e.detail === 'soft_ask_shown') {
    const wrapper = document.getElementById('moe-push-div');
    if (wrapper) {
      wrapper.style.display = 'block';
    }
  }
});

const logVitals = ({ name, value, delta, entries, id, ...rest }) => {
  if (window.location.hostname !== 'www.fanatical.com') {
    console.info(`Core Web Vitals - ${name}`, { name, value, delta, entries, id, ...rest }) // eslint-disable-line
  } else {
    store.dispatch({ type: 'WEB_VITAL', payload: { name, value, delta, id } });
  }
};

onCLS(logVitals);
onFCP(logVitals);
onTTFB(logVitals);
onINP(logVitals);
onLCP(logVitals);
