import React, { useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import 'Components/NavBar/AutoSearch/AutoSearchWrapper.scss';

const AutoSearch = lazy(() => import(/* webpackChunkName: "AutoSearch" */ 'Components/NavBar/AutoSearch/AutoSearch'));

const messages = defineMessages({
  placeholder: {
    id: 'autosearch-wrapper.search-placeholder',
    defaultMessage: 'Search PC, Mac, Linux Games',
  },
});

function AutoSearchWrapper({
  show,
  setShowMobile,
  intialFocus,
  intl: { formatMessage },
}) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isFocussed, setHasFocus] = useState(intialFocus);
  const [value, setValue] = useState(new URL(window.location).searchParams.get('search') || '');

  const enter = () => {
    if (!hasLoaded) {
      setHasLoaded(true);
    }
  };

  const focus = () => {
    setHasFocus(true);
    if (!hasLoaded) {
      setHasLoaded(true);
    }
  };

  const blur = () => {
    setHasFocus(false);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const placeholderInput = (
    <div aria-haspopup role="combobox" aria-controls="react-autowhatever-1" aria-owns="react-autowhatever-1" aria-expanded="false" className="react-autosuggest__container">
      <div className="input-group">
        <input
          autoFocus
          type="text"
          aria-label="search"
          className="form-control autosearch-form"
          placeholder={formatMessage(messages.placeholder)}
          title={formatMessage(messages.placeholder)}
          value={value}
          tabIndex={0}
          onMouseEnter={enter}
          onBlur={blur}
          onFocus={focus}
          onChange={onChange}
        />
        <Link to="/search" rel="nofollow" className="input-group-append">
          <button className="btn border-0" aria-label="Search">
            <FontAwesomeIcon icon="search" color="#bdbdbd" title="Search" />
          </button>
        </Link>
      </div>
    </div>
  );

  return (
    <div className="autosearch">
      {show || hasLoaded ?
        <Suspense fallback={placeholderInput}>
          <AutoSearch value={value} setShowMobile={setShowMobile} isFocussed={isFocussed} />
        </Suspense>
        :
        placeholderInput
      }
    </div>
  );
}

AutoSearchWrapper.propTypes = {
  show: PropTypes.bool,
  setShowMobile: PropTypes.func,
  intialFocus: PropTypes.bool,
};

AutoSearchWrapper.defaultProps = {
  show: false,
  setShowMobile: () => {},
  intialFocus: false,
};

export default injectIntl(AutoSearchWrapper);
