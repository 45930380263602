import React, { Suspense, lazy } from 'react';
import { IntlProvider } from 'react-intl';
import { Router, Switch, Redirect } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  faSlidersH,
  faSortAmountDown,
  faBars,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSpinner,
  faRssSquare,
  faHeart,
  faStar,
  faGamepad,
  faMouse,
  faHeadset,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faLock,
  faLink,
  faSync,
  faExclamationTriangle,
  faExclamation,
  faSearch,
  faSearchPlus,
  faRocket,
  faExternalLinkAlt,
  faShoppingCart,
  faCartPlus,
  faShoppingBasket,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faInfo,
  faUser,
  faCog,
  faBookOpen,
  faQuestionCircle,
  faTrashAlt,
  faMagic,
  faArrowCircleRight,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faGrin,
  faTag,
  faGraduationCap,
  faHeadphonesAlt,
  faLaptopCode,
  faLaptop,
  faGlobeEurope,
  faGlobeAmericas,
  faCouch,
  faMale,
  faHome,
  faThermometerEmpty,
  faVrCardboard,
  faSignOutAlt,
  faLightbulb,
  faKey,
  faCashRegister,
  faPlus,
  faMinus,
  faHandHoldingHeart,
  faGift,
  faTh,
  faThList,
  faUserFriends,
  faCalendarAlt,
  faStarHalf,
  faCreditCard,
  faEnvelope,
  faThumbsUp,
  faThumbsDown,
  faFilter,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faCertificate,
  faDollarSign,
  faFileDownload,
  faVolumeUp,
  faVolumeOff,
  faBell,
  faEdit,
  faAward,
  faTrophy,
  faTicketAlt,
  faCrosshairs,
  faDragon,
  faUsers,
  faFistRaised,
  faCar,
  faFighterJet,
  faHatWizard,
  faGhost,
  faUndoAlt,
  faWrench,
  faEquals,
  faCheckSquare,
  faListUl,
  faDesktop,
  faLanguage,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import {
  faReddit,
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faSteam,
  faTwitch,
  faWindows,
  faApple,
  faLinux,
  faChrome,
  faFirefox,
  faDiscord,
  faAndroid,
  faWhatsapp,
  faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons';
import {
  faClock,
  faHeart as faHeartO,
  faBell as faBellO,
  faPlayCircle,
  faHandshake as faHandshakeO,
} from '@fortawesome/free-regular-svg-icons';

import { history } from 'Redux/store';
import isBot from 'Helpers/SEO/is-bot';
// import { fetchSiteBrand } from 'Redux/ducks/site-brand';
import AnonymousId from 'Containers/Auth/AnonymousId';
import ScrollToTop from 'Containers/Utility/ScrollToTop';
import NavBar from 'Containers/NavBar/NavBar';
import ErrorBoundary from 'Components/Utility/ErrorBoundary';
import ModalGroupContainer from 'Containers/App/ModalGroupContainer';
import FeedbackPopupContainer from 'Containers/Utility/FeedbackPopups/FeedbackPopupContainer';
import FooterGroupContainer from 'Containers/App/FooterGroupContainer';
import Route from 'Containers/App/Route';
import SkeletonPlaceholder from 'Components/Utility/SkeletonPlaceholder';
import ViewportProvider from 'Components/App/ViewportProvider';
import VisibleVoucherContainer from 'Components/Vouchers/VisibleVoucherContainer';
import CookieConsentWrapper from 'Components/Cookie/CookieConsentWrapper';
import { MetaTag } from 'Helpers/SEO/Tags';
import { HitCardPopupProvider } from 'Context/HitCardPopupContext';
import HitCardPopup from 'Components/Cards/HitCardPopup/HitCardPopup';

const locale = window.locale || 'en';

library.add(
  faReddit,
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
  faSteam,
  faTwitch,
  faWindows,
  faApple,
  faLinux,
  faChrome,
  faFirefox,
  faDiscord,
  faAndroid,
  faWhatsapp,
  faBars,
  faSlidersH,
  faSortAmountDown,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSpinner,
  faRssSquare,
  faStar,
  faGamepad,
  faMouse,
  faHeadset,
  faHeart,
  faHeartO,
  faBellO,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faLock,
  faLink,
  faSync,
  faExclamationTriangle,
  faExclamation,
  faSearch,
  faSearchPlus,
  faRocket,
  faShoppingCart,
  faCartPlus,
  faShoppingBasket,
  faExternalLinkAlt,
  faCog,
  faBookOpen,
  faQuestionCircle,
  faTrashAlt,
  faMagic,
  faArrowCircleRight,
  faArrowAltCircleRight,
  faArrowLeft,
  faArrowRight,
  faGrin,
  faClock,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faInfo,
  faUser,
  faTag,
  faGraduationCap,
  faHeadphonesAlt,
  faGlobeEurope,
  faGlobeAmericas,
  faCouch,
  faMale,
  faHome,
  faThermometerEmpty,
  faVrCardboard,
  faLightbulb,
  faSignOutAlt,
  faKey,
  faCashRegister,
  faPlayCircle,
  faPlus,
  faMinus,
  faHandHoldingHeart,
  faGift,
  faTh,
  faThList,
  faUserFriends,
  faCalendarAlt,
  faStarHalf,
  faCreditCard,
  faEnvelope,
  faThumbsUp,
  faThumbsDown,
  faFilter,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faCertificate,
  faLaptopCode,
  faLaptop,
  faDollarSign,
  faFileDownload,
  faVolumeUp,
  faVolumeOff,
  faBell,
  faEdit,
  faAward,
  faTrophy,
  faTicketAlt,
  faTelegramPlane,
  faCrosshairs,
  faDragon,
  faUsers,
  faFistRaised,
  faCar,
  faFighterJet,
  faHatWizard,
  faGhost,
  faUndoAlt,
  faWrench,
  faEquals,
  faCheckSquare,
  faListUl,
  faDesktop,
  faLanguage,
  faRedo,
  faHandshakeO,
);

const { translations } = window;

const HomePageContainer = lazy(() => import(/* webpackChunkName: "HomePage" */ 'Containers/HomePage/HomePageContainer'));

const AboutUsPage = lazy(() => import(/* webpackChunkName: "AboutUs" */ 'Components/AboutUs/AboutUs'));

const AffiliatesPage = lazy(() => import(/* webpackChunkName: "Affiliates" */ 'Components/Affiliates/Affiliates'));

const ExtensionPage = lazy(() => import(/* webpackChunkName: "BrowserExtension" */ 'Components/BrowserExtension/ExtensionPage'));
const ExtensionUninstallPage = lazy(() => import(/* webpackChunkName: "BrowserExtension" */ 'Components/BrowserExtension/ExtensionUninstallPage'));

const RedeemCodeContainer = lazy(() => import(/* webpackChunkName: "RedeemCode" */ 'Containers/RedeemCode/RedeemCodeContainer'));

const CommunityPage = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/Community/Community'));
const CookiePolicy = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/CookiePolicy/CookiePolicy'));
const NotFound = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/NotFound/NotFound'));
const RedeemGiftContainer = lazy(() => import(/* webpackChunkName: "Pages" */ 'Containers/Gift/RedeemGiftContainer'));
const SplitTestSwitch = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/SplitTestSwitch/SplitTestSwitch'));
const Vouchers = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/Vouchers/Vouchers'));
const ReferralsPage = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/ReferFriend/ReferralPage'));

const HpOmenContainer = lazy(() => import(/* webpackChunkName: "HPOmen" */ 'Containers/Partners/HpOmenContainer'));
const HpOmenOasis = lazy(() => import(/* webpackChunkName: "HPOmen" */ 'Components/Partners/HpOmenOasis/HpOmenOasis'));
const HpOmenGamingHub = lazy(() => import(/* webpackChunkName: "HPOmen" */ 'Components/Partners/HpOmenGamingHub/HpOmenGamingHub'));

const Pickaxe = lazy(() => import(/* webpackChunkName: "HPOmen" */ 'Components/Partners/Pickaxe/Pickaxe'));

const WelcomePage = lazy(() => import(/* webpackChunkName: "HPOmen" */ 'Components/Welcome/Welcome'));
const WelcomeNewUserPage = lazy(() => import(/* webpackChunkName: "HPOmen" */ 'Components/Affiliates/WelcomeNewUser'));

const AccountConfirmContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/AccountConfirm/AccountConfirmContainer'));
const EmailChangeConfirmContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/EmailChangeConfirm/EmailChangeConfirmContainer'));
const MagicLoginContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/Auth/MagicLoginContainer'));
const PasswordReset = lazy(() => import(/* webpackChunkName: "Account" */ 'Components/PasswordReset/PasswordReset'));
const WishlistContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/Wishlist/WishlistContainer'));
const EpicReturnContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/Epic/EpicReturnContainer'));

const AccountRoute = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Containers/App/AccountRoute'));
const AccountOverviewContainer = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Containers/Account/AccountOverviewContainer'));
const LoginAndSecurity = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/LoginAndSecurity/LoginAndSecurity'));
const LinkedAccounts = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/LinkedAccounts/LinkedAccounts'));
const ContactPreferences = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/ContactPreferences/ContactPreferences'));
const WebsiteSettings = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/WebsiteSettings/WebsiteSettings'));
const PaymentInformation = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/PaymentInformation/PaymentInformation'));
const OrdersAndKeys = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/OrdersAndKeys/OrdersAndKeys'));
const OrderContainer = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Containers/Order/OrderContainer'));
const Support = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/Support/Support'));
const PreferenceCenter = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/ContactPreferences/PreferenceCenter'));
const CouponsAndRewards = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/CouponsAndRewards/CouponsAndRewards'));
const KeyLibrary = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/Account/ProductLibrary/KeyLibrary'));
const OrderItemReviewForm = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Components/ProductReviews/OrderItemReviews/OrderItemReviewForm'));
const OrderItemReviewsContainer = lazy(() => import(/* webpackChunkName: "AccountArea" */ 'Containers/ProductReviews/OrderItemReviewsContainer'));

const FacetedFacetSearch = lazy(() => import(/* webpackChunkName: "FacetedFacetSearch" */ 'Components/FacetedFacetSearch/FacetedFacetSearch'));
const FacetOrderedList = lazy(() => import(/* webpackChunkName: "FacetSearch" */ 'Components/FacetSearch/FacetOrderedList'));
const FacetSearch = lazy(() => import(/* webpackChunkName: "FacetSearch" */ 'Components/FacetSearch/FacetSearch'));

const GamesLike = lazy(() => import(/* webpackChunkName: "GamesLike" */ 'Components/FacetSearch/GamesLike'));

const AllGames = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/AllGames'));
const AllDLC = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/AllDLC'));
const UpcomingGames = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/UpcomingGames'));
const NewReleases = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/NewReleases'));
const Released = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/Released'));
const BundlesContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/Bundle/BundlesContainer'));
const BundlefestContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/Bundle/BundlefestContainer'));
const EndingSoonContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/EndingSoon'));
const LatestDealsContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/LatestDeals'));
const FreeToPlayContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/FreeToPlay'));
const MostWanted = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Components/MostWanted/MostWanted'));
const PlatformLinux = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/PlatformLinux'));
const PlatformMac = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/PlatformMac'));
const GamesUnder = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/GamesUnder'));
const SteamDeck = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/SteamDeck'));
const XboxGames = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/XboxGames'));
const RecentlyViewedPage = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Components/RecentlyViewed/RecentlyViewedPage'));
const TopSellers = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Components/TopSellers/TopSellers'));
const TrendingDealsContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/TrendingDeals'));
const MostGiftedContainer = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/MostGiftedContainer'));
const NotOnSale = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/NotOnSale'));
const DollarDealsContainer = lazy(() => import(/* webpackChunkName: "DollarDeals" */ 'Containers/DollarDeals/DollarDealsContainer'));
const GiftCards = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Containers/StaticSearch/GiftCards'));
const FantasyVersePage = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Components/FantasyVerse/FantasyVersePage'));
const MysteryPage = lazy(() => import(/* webpackChunkName: "SearchPages" */ 'Components/Mystery/MysteryPage'));

const OnSaleSectionsContainer = lazy(() => import(/* webpackChunkName: "OnSale" */ 'Containers/OnSale/OnSaleSectionsContainer'));
const SummerSaleContainer = lazy(() => import(/* webpackChunkName: "OnSale" */ 'Containers/OnSale/SummerSaleContainer'));
const CyberMonday = lazy(() => import(/* webpackChunkName: "OnSale" */ 'Components/Templates/CyberMonday'));
const BlackFriday = lazy(() => import(/* webpackChunkName: "OnSale" */ 'Components/Templates/BlackFriday'));

const HallOfFameContainer = lazy(() => import(/* webpackChunkName: "OnSale" */ 'Components/HallOfFame/HallOfFameContainer'));

const SearchPage = lazy(() => import(/* webpackChunkName: "InstantSearch" */ 'Components/Search/Search'));

const GiftGuide = lazy(() => import(/* webpackChunkName: "GiftGuide" */ 'Components/Gift/GiftGuide'));

const BlogPostContainer = lazy(() => import(/* webpackChunkName: "Blog" */ 'Containers/Blog/BlogPostContainer'));
const BlogPostsContainer = lazy(() => import(/* webpackChunkName: "Blog" */ 'Containers/Blog/BlogPostsContainer'));
const BlogPreviewContainer = lazy(() => import(/* webpackChunkName: "Blog" */ 'Containers/Blog/BlogPreviewContainer'));

const Cart = lazy(() => import(/* webpackChunkName: "Cart" */ 'Components/Checkout/Checkout'));

const ReceiptContainer = lazy(() => import(/* webpackChunkName: "Receipt" */ 'Containers/Receipt/ReceiptContainer'));

const PickAndMixContainer = lazy(() => import(/* webpackChunkName: "ProductPage" */ 'Containers/PickAndMix/PickAndMixContainer'));

const ProductContainer = lazy(() => import(/* webpackChunkName: "ProductPage" */ 'Containers/Product/ProductContainer'));

const ProductDLCContainer = lazy(() => import(/* webpackChunkName: "ProductDLC" */ 'Containers/Product/SubPages/ProductDLCContainer'));

const ResetPassword = lazy(() => import(/* webpackChunkName: "ResetPassword" */ 'Components/Auth/ResetPassword'));

const Payment = lazy(() => import(/* webpackChunkName: "Payment" */ 'Components/Payment/Payment'));
const PaymentReturnContainer = lazy(() => import(/* webpackChunkName: "Payment" */ 'Containers/Payment/PaymentReturnContainer'));

const BillingContainer = lazy(() => import(/* Billing */ 'Containers/Billing/BillingContainer'));

const OptinContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/Optin/OptinContainer'));
const ImagePreviewContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/Image/ImagePreviewContainer'));
const FanFavouritesContainer = lazy(() => import(/* webpackChunkName: "Account" */ 'Containers/FanFavourites/FanFavouritesContainer'));
const FeaturedCarouselFullPreviewContainer = lazy(() => import(/* webpackChunkName: "AdminPreview" */ 'Containers/previews/FeaturedCarouselFullPreviewContainer'));

const FandomOauthReturnContainer = lazy(() => import(/* webpackChunkName: "FandomOauthReturnContainer" */ 'Containers/Auth/FandomOauthReturnContainer'));

const AdminLoginAs = lazy(() => import(/* webpackChunkName: "AdminLoginAs" */ 'Containers/Auth/AdminLoginAs'));

const Competitions = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/Competitions/Competitions'));

const Showcase = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/Showcase/Showcase'));
const ShowcaseProduct = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/Showcase/ShowcaseProduct'));

const injectBoundary = WrappedComponent => props => (
  <ErrorBoundary>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);

const noPrerender = !!window.location.hash;

const AppIntl = ({ siteTheme, siteBrand, disableMinHeight }) => (
  <Router history={history} basename="/">
    <div className={`${siteTheme === 'light' ? 'site-light-theme' : 'site-dark-theme'} brand-${siteBrand}`}>
      <>
        {noPrerender &&
          <MetaTag name="prerender-status-code" content="404" />
        }
        {noPrerender &&
          <MetaTag name="robots" content="noindex" />
        }
      </>
      {!isBot() &&
        <CookieConsentWrapper />
      }
      <ScrollToTop />
      <AnonymousId />

      <NavBar />

      <div className={classNames({ content: !disableMinHeight })}>
        <Suspense fallback={<SkeletonPlaceholder />}>
          <Switch>
            <Route exact path="/" component={HomePageContainer} />

            <Route exact path="/black-friday" component={BlackFriday} />
            <Route exact path="/cyber-monday" component={CyberMonday} />

            <Route exact noPrerender path="/search" component={SearchPage} />
            <Route exact path="/blog" component={BlogPostsContainer} />
            <Route exact path="/blog/authors/:authorSlug" component={BlogPostsContainer} />
            <Route exact noPrerender path="/blog/preview" component={BlogPreviewContainer} />
            <Route exact path="/blog/category/:category(games|news|develop)" component={BlogPostsContainer} />
            <Route exact path="/blog/:slug" component={BlogPostContainer} />

            <Route exact path="/mac-games" component={PlatformMac} />
            <Route exact path="/linux-games" component={PlatformLinux} />
            <Route exact path="/steam-deck-games" component={SteamDeck} />
            <Route exact path="/xbox-games" component={XboxGames} />
            <Route exact path="/collections/party-games" component={HpOmenOasis} />
            <Route exact path={'/games-under/:price(5|10|20)'} component={GamesUnder} />

            <Route exact path={'/:facet(vr-games)/:facets*'} component={FacetedFacetSearch} />

            <Route exact path={'/:facet(publishers|franchises|collections|categories|games-like)/'} component={FacetOrderedList} />
            <Route exact path={'/:facet(publishers|franchises|collections|categories)/:slug'} component={FacetSearch} />
            <Route exact path={'/games-like/:slug'} component={GamesLike} />

            <Route exact path="/all-games" component={AllGames} />
            <Route exact path="/all-dlc" component={AllDLC} />
            <Route exact path="/bundle" component={BundlesContainer} />
            <Route exact path="/bundle/books" component={BundlesContainer} />
            <Route exact path="/bundle/games" component={BundlesContainer} />
            <Route exact path="/bundle/software" component={BundlesContainer} />
            <Route exact path="/bundlefest" component={BundlefestContainer} />

            <Route exact path="/top-sellers" component={TopSellers} />
            <Route exact path="/on-sale" component={OnSaleSectionsContainer} />
            <Route exact path="/rewards" component={SummerSaleContainer} />
            <Route exact path="/upcoming-games" component={UpcomingGames} />
            <Route exact path="/recently-added" component={Released} />
            <Route exact path="/new-releases" component={NewReleases} />
            <Route exact path="/latest-deals" component={LatestDealsContainer} />
            <Route exact path="/ending-soon" component={EndingSoonContainer} />
            <Route exact path="/most-wanted" component={MostWanted} />
            <Route exact path="/most-gifted" component={MostGiftedContainer} />
            <Route exact path="/recently-viewed" component={RecentlyViewedPage} />
            <Route exact path="/trending-deals" component={TrendingDealsContainer} />
            <Route exact path="/full-price-games" component={NotOnSale} />
            <Route exact path="/dollar-deals" component={DollarDealsContainer} />
            <Route exact path="/free-to-play" component={FreeToPlayContainer} />
            <Route exact path="/fantasyverse" component={FantasyVersePage} />
            <Route exact path="/mystery" component={MysteryPage} />

            <Route exact path="/gift-cards/:filter(ea-play|nintendo|playstation|roblox|xbox|razer)?" component={GiftCards} />

            <Route exact noPrerender componentProps={{ isPreview: true }} path="/:type(game|bundle|dlc|book|comic|audio|video|software|gift-card)/preview/:id" component={ProductContainer} />
            <Route exact noPrerender path="/pick-and-mix/preview/:id" component={PickAndMixContainer} />
            <Route exact noPrerender path="/image/preview/:id" component={ImagePreviewContainer} />
            <Route exact noPrerender path="/preview/featured-carousel" component={FeaturedCarouselFullPreviewContainer} />

            <Route exact path="/(game|dlc|software)/:slug/dlc" component={ProductDLCContainer} />

            <Route exact path="/:type(game|bundle|dlc|book|comic|audio|video|software|gift-card)/:slug" component={ProductContainer} />

            <Route exact path="/pick-and-mix/:slug" component={PickAndMixContainer} />

            <Route exact path="/about-us" component={AboutUsPage} />
            <Route exact path="/affiliates" component={AffiliatesPage} />
            <Route exact path="/community" component={CommunityPage} />
            <Route exact path="/referrals" component={ReferralsPage} />
            <Route exact path="/coupons-vouchers-codes" component={Vouchers} />
            <Route exact path="/cookie-policy" component={CookiePolicy} />

            <Route exact path="/browser-extension" component={ExtensionPage} />
            <Route exact noPrerender path="/browser-extension/uninstall" component={ExtensionUninstallPage} />

            <Route exact path="/cart" component={Cart} />

            <Route exact path="/redeem-code" component={RedeemCodeContainer} />
            <Route exact noPrerender path="/redeem-gift" component={RedeemGiftContainer} />
            <Route exact path="/wishlist" component={WishlistContainer} />
            <Route exact noPrerender path="/wishlist/sync">
              <Redirect to="/wishlist?redirectFrom=/wishlist/sync" />
            </Route>
            <Route exact noPrerender path="/wishlist/:wishlistId" component={WishlistContainer} />
            <Route exact noPrerender path="/new-password" component={ResetPassword} />

            <Route exact noPrerender path="/fandom-auth-return" component={FandomOauthReturnContainer} />

            <Route exact noPrerender path="/billing" component={BillingContainer} />
            <Route exact noPrerender path="/payment" render={injectBoundary(Payment)} />
            <Route exact noPrerender path="/payment-return/:orderId/data" component={PaymentReturnContainer} />

            <Route exact path="/gift-guide" component={GiftGuide} />
            <Route exact path="/recommended" component={FanFavouritesContainer} />
            <Route exact path="/best-games-of-:year(2022|2023|2024|2025)" component={HallOfFameContainer} />

            <Route exact noPrerender path="/welcome" component={WelcomePage} />
            <Route exact noPrerender path="/welcome-new-user" component={WelcomeNewUserPage} />
            <Route exact noPrerender path="/partners/hp-omen" component={HpOmenContainer} />
            <Route exact noPrerender path="/partners/hp-omen-gaming-hub" component={HpOmenGamingHub} />
            <Route exact noPrerender path="/pickaxe" component={Pickaxe} />

            <Route
              exact
              noPrerender
              path="/newconfirm/:token1/:token2/:date"
              component={AccountConfirmContainer}
            />

            <Route
              exact
              noPrerender
              path="/email-change-confirm/:token"
              component={EmailChangeConfirmContainer}
            />

            <Route exact noPrerender path="/password/reset/:token" component={PasswordReset} />
            <Route exact noPrerender path="/login/magic/:token" component={MagicLoginContainer} />

            <Route exact noPrerender path="/epic-return" component={EpicReturnContainer} />

            <Route exact noPrerender path="/receipt" component={ReceiptContainer} />

            <AccountRoute exact path="/account/login" component={LoginAndSecurity} />
            <AccountRoute exact path="/account/linked-accounts" component={LinkedAccounts} />
            <AccountRoute exact path="/account/rewards" component={CouponsAndRewards} />
            <AccountRoute exact path="/account/wishlist" component={WishlistContainer} />
            <AccountRoute exact path="/account/wishlist/:wishlistId" component={WishlistContainer} />
            <AccountRoute exact path="/account/preferencecenter" component={PreferenceCenter} />
            <AccountRoute exact path="/account/settings" component={WebsiteSettings} />
            <AccountRoute exact path="/account/payment" component={PaymentInformation} />
            <AccountRoute exact path="/account/contact" component={ContactPreferences} />
            <AccountRoute exact path="/account" component={AccountOverviewContainer} />
            <AccountRoute exact path="/support" component={Support} />
            <AccountRoute exact path="/orders" component={OrdersAndKeys} />
            <AccountRoute exact path="/orders/:id" component={OrderContainer} />
            <AccountRoute exact path="/orders/:id/:token1/:token2/:date" component={OrderContainer} />
            <AccountRoute exact path="/product-library" component={KeyLibrary} />
            <AccountRoute exact path="/reviews" component={OrderItemReviewsContainer} />
            <AccountRoute exact path="/review/:productId" component={OrderItemReviewForm} />

            <Route exact noPrerender path="/optin/:token" component={OptinContainer} />

            <Route
              exact noPrerender path="/throw-error/:message?"
              component={injectBoundary((errorProps) => {
                throw new Error(errorProps.match.params.message);
              })}
            />

            <Route exact noPrerender path="/admin-login-as/:token" component={AdminLoginAs} />

            <Route exact noPrerender path="/splittest" component={SplitTestSwitch} />

            <Route exact noPrerender path="/competitions" component={Competitions} />

            <Route exact path="/showcase" component={Showcase} />
            <Route exact noPrerender path="/showcase/:slug" component={ShowcaseProduct} />

            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </div>

      <VisibleVoucherContainer />

      <HitCardPopup />

      <FooterGroupContainer />

      <ModalGroupContainer />

      <FeedbackPopupContainer />
    </div>
  </Router>
);

AppIntl.displayName = 'AppIntl';

AppIntl.propTypes = {
  siteTheme: PropTypes.string.isRequired,
  siteBrand: PropTypes.string,
  disableMinHeight: PropTypes.bool,
};

AppIntl.defaultProps = {
  siteBrand: 'fanatical',
  disableMinHeight: false,
};

const disableMinHeightPaths = ['/welcome-new-user', '/showcase'];

const mapStateToProps = ({
  siteTheme,
  siteBrand,
  router: { location: { pathname } },
  // Be very careful adding connect props to App, it can cause whole App
  // to rerender and may mess with the router - See DEV-4187
}) => ({
  siteTheme,
  siteBrand,
  disableMinHeight: disableMinHeightPaths.includes(pathname) || pathname.includes('/showcase/'),
});

const ConnectedApp = connect(mapStateToProps)(AppIntl);


function App() {
  return (
    <IntlProvider locale={locale} messages={translations}>
      <HitCardPopupProvider>
        <ViewportProvider>
          <ErrorBoundary>
            <ConnectedApp />
          </ErrorBoundary>
        </ViewportProvider>
      </HitCardPopupProvider>
    </IntlProvider>
  );
}

export default App;
