import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Tooltip from 'Components/Molecules/Tooltip';
import TrustpilotStrip from 'Components/NavBar/TrustpilotStrip/TrustpilotStrip';

import 'Components/Product/Usp/SmallUspBar.scss';

export default function UspBar({ mobileEnabled }) {
  const [slideNumber, setSlideNumber] = useState(1);

  useEffect(() => {
    let timer;
    if (mobileEnabled) {
      timer = setTimeout(() => {
        if (slideNumber === 1) setSlideNumber(2);
        if (slideNumber === 2) setSlideNumber(3);
        if (slideNumber === 3) setSlideNumber(1);
      }, 5000);
    }

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [setSlideNumber, slideNumber, mobileEnabled]);

  /* eslint-disable arrow-body-style */
  const componentOne = () => {
    return (
      <div className="UspBar__slide">
        <Tooltip
          ariaLabel="100% Safe and secure dropdown. Expand for more details"
          tabIndex={-1}
          content={
            <FormattedMessage
              id="uspbar.popout"
              defaultMessage="All payments encrypted. {br}{br} Dedicated in-house customer support team."
              values={{ br: <br /> }}
            />
          }
        >
          <div className="d-flex align-items-center align-self-center text-center">
            <div className="pr-2 UspBar__slide__icon">
              <FontAwesomeIcon size="lg" icon="lock" />
            </div>

            <FormattedMessage
              id="uspbar.official-safe"
              defaultMessage="100% Safe and Secure"
            />

            {!mobileEnabled &&
              <div className="d-flex align-items-center pl-1">
                <FontAwesomeIcon
                  size="lg"
                  icon="chevron-down"
                  className="small-chevron"
                />
              </div>
            }
          </div>
        </Tooltip>
      </div>
    );
  };

  const componentTwo = () => {
    return (
      <div className="UspBar__slide">
        <TrustpilotStrip minimal={mobileEnabled} logoColor="black" />
      </div>
    );
  };

  const componentThree = () => {
    return (
      <div className="UspBar__slide">
        <Tooltip
          tabIndex={-1}
          ariaLabel="Instant Digital Delivery dropdown. Expand for more details"
          content={
            <FormattedMessage
              id="usp-bar.point.delivery.tooptip"
              defaultMessage="Pre-orders will be delivered by release day. {br}{br} Less than 1% of all orders are flagged for manual authorization."
              values={{ br: <br /> }}
            />
          }
          componentClassName="justify-content-end"
          tooltipClassName="tooltip-custom"
        >

          <div className="d-flex align-items-center align-self-center text-center">
            <div className="pr-2">
              <FontAwesomeIcon size="lg" icon="rocket" />
            </div>

            <FormattedMessage
              id="usp-bar.point.delivery"
              defaultMessage="Instant Digital Delivery"
            />

            {!mobileEnabled &&
              <div className="d-flex align-items-center pl-1">
                <FontAwesomeIcon
                  size="lg"
                  icon="chevron-down"
                  className="small-chevron"
                />
              </div>
            }
          </div>

        </Tooltip>
      </div>
    );
  };

  if (mobileEnabled) {
    return (
      <div className="small-usp-bar MobileUspBar">
        <div className={classNames('MobileUspBar__message MobileUspBar__message--one', { 'MobileUspBar__message--visible': slideNumber === 1 })}>
          <div className="MobileUspBar__message__content">
            <FontAwesomeIcon size="lg" icon="lock" />
            <FormattedMessage
              id="uspbar.official-safe"
              defaultMessage="100% Safe and Secure"
            />
          </div>
        </div>

        <div className={classNames('MobileUspBar__message MobileUspBar__message--two', { 'MobileUspBar__message--visible': slideNumber === 2 })}>
          <TrustpilotStrip minimal={mobileEnabled} logoColor="black" />
        </div>

        <div className={classNames('MobileUspBar__message MobileUspBar__message--three', { 'MobileUspBar__message--visible': slideNumber === 3 })}>
          <div className="MobileUspBar__message__content">
            <FontAwesomeIcon size="lg" icon="rocket" />
            <FormattedMessage
              id="usp-bar.point.delivery"
              defaultMessage="Instant Digital Delivery"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="small-usp-bar">
      <div className="container d-flex justify-content-between align-items-center align-self-center">
        <div>
          {componentOne()}
        </div>
        <div>
          {componentTwo()}
        </div>
        <div>
          {componentThree()}
        </div>
      </div>
    </div>
  );
}

UspBar.propTypes = {
  trustpilot: PropTypes.shape({
    total: PropTypes.number,
    score: PropTypes.shape({
      stars: PropTypes.number,
      trustScore: PropTypes.number,
    }),
  }),
  mobileEnabled: PropTypes.bool,
};

UspBar.defaultProps = {
  trustpilot: {
    total: 0,
    score: {
      stars: 0,
      trustScore: 0,
    },
  },
  mobileEnabled: false,
};
