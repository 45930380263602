import React from 'react';
import { Link } from 'react-router-dom';
import { NavbarBrand, NavLink } from 'reactstrap';

export default function BrandFanatical() {
  return (
    <NavbarBrand tag="div">
      <NavLink aria-label="homepage, graphic, Fanatical logo" tag={Link} to="/" />
    </NavbarBrand>
  );
}
