import React from 'react';

import Loading from 'Components/Utility/Loading';

let hasMounted = false;
let mountUrl;
let skeletonString;

// If there is skeleton content in the dom already (from cloudflare worker), grab/cache that
// and return it instead of the loading bars to stop good content (usually LCP) disappearing
// then re-appearing

export default function SkeletonPlaceholder() {
  const placeholderElement = document.querySelector('.content.body-placeholder');
  const contentElement = document.querySelector('.content');

  // Find the skeleton html and cache it
  if (placeholderElement && placeholderElement.innerHTML && !hasMounted) {
    skeletonString = placeholderElement.innerHTML;
    mountUrl = window.location.pathname;
    hasMounted = true;
  }

  // Only return the skeleton html if it's still needed
  if (
    skeletonString &&
    window.location.pathname === mountUrl && (
      (placeholderElement && placeholderElement.innerHTML) ||
      (contentElement && (
        contentElement.innerHTML.length === 0 ||
        contentElement.innerHTML.includes('skeleton-placeholder')
      ))
    )
  ) {
    return <div className="skeleton-placeholder" dangerouslySetInnerHTML={{ __html: skeletonString }} />; // eslint-disable-line
  }

  return <Loading />;
}
