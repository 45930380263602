import { memo } from 'react';
import { useMeta, useLink, useTitle, useScript } from 'hoofd';

export const MetaTag = memo((props) => {
  useMeta(props);
});

export const LinkTag = memo((props) => {
  useLink({ ...props, 'data-hoofd': true });
});

export const TitleTag = memo((props) => {
  useTitle(props.children);
});

export const ScriptTag = memo((props) => {
  useScript({ ...props, text: props.script });
});
