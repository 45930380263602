import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';


import useCountdownTimer from 'Components/Hooks/useCountdownTimer';


export default function CountdownTimer({ value, cardTimer, timerTitle, daysText }) {
  const { days, hours, minutes, seconds } = useCountdownTimer(value);
  // if (days > 1400) return false;

  const dealSecondsRemaining = (value - Date.now()) / 1000;
  const endingSoon = dealSecondsRemaining < 172800; // 48 hours
  const showTitle = timerTitle;

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) return false;

  if (days >= 2) {
    return (
      <div className={classNames('CountdownTimer', { 'CountdownTimer--cardTimer': cardTimer })}>
        {showTitle &&
          <div className="CountdownTimer__title">
            {timerTitle}
          </div>
        }
        {!daysText &&
          <div className="CountdownTimer__clock CountdownTimer__clock--days">
            <FormattedMessage
              id="countdown-timer.days-left"
              defaultMessage="{days} Days Left"
              values={{ days }}
            />
          </div>
        }
        {daysText &&
          <div className="CountdownTimer__clock CountdownTimer__clock--days">
            <FormattedMessage
              id="countdown-timer.days"
              defaultMessage="{days} Days"
              values={{ days }}
            />
          </div>
        }
      </div>
    );
  }

  return (
    <div className={classNames('CountdownTimer', { 'CountdownTimer--cardTimer': cardTimer }, { 'CountdownTimer--endingSoon': endingSoon })}>
      {showTitle &&
        <div className="CountdownTimer__title">
          {timerTitle}
        </div>
      }
      <div className="CountdownTimer__clock">
        {days !== 0 &&
          <>
            <span>
              {days < 10 && <>0</>}
              {days}
              D
            </span>

            <span>
              :
            </span>
          </>
        }
        <span>
          {hours < 10 && <>0</>}
          {hours}
          H
        </span>

        <span>
          :
        </span>

        <span>
          {minutes < 10 && <>0</>}
          {minutes}
          M
        </span>

        <span>
          :
        </span>

        <span>
          {seconds < 10 && <>0</>}
          {seconds}
          S
        </span>
      </div>
    </div>
  );
}

CountdownTimer.propTypes = {
  value: PropTypes.number.isRequired,
  cardTimer: PropTypes.bool,
  timerTitle: PropTypes.string,
  daysText: PropTypes.bool,
};

CountdownTimer.defaultTypes = {
  cardTimer: false,
  timerTitle: '',
  daysText: false,
};
