import store from 'Redux/store';
import { countryCode } from 'Helpers/Geo/data';

const LightboxUtils = {};

LightboxUtils.removeLightboxVars = () => {
  localStorage.removeItem('LIGHTBOX-email');
  localStorage.removeItem('LIGHTBOX-email_confirmed');
  localStorage.removeItem('LIGHTBOX-email_newsletter');
  localStorage.removeItem('LIGHTBOX-language');
  localStorage.removeItem('LIGHTBOX-country');
};

LightboxUtils.setLightboxVars = () => {
  const state = store.getState();
  const auth = state.auth || {};
  const email = auth.email || '';
  if (email === '') {
    LightboxUtils.removeLightboxVars();
    return;
  }
  const emailConfirmed = auth.email_confirmed || false;
  const emailNewsletter = auth.email_newsletter || false;
  const languageObject = state.language || {};
  const language = languageObject.from || 'en';
  const country = countryCode || 'GB';
  localStorage.setItem('LIGHTBOX-email', email);
  localStorage.setItem('LIGHTBOX-email_confirmed', emailConfirmed);
  localStorage.setItem('LIGHTBOX-email_newsletter', emailNewsletter);
  localStorage.setItem('LIGHTBOX-language', language);
  localStorage.setItem('LIGHTBOX-country', country);
};

LightboxUtils.getLightboxVars = () => {
  const email = localStorage.getItem('LIGHTBOX-email');
  const emailConfirmed = localStorage.getItem('LIGHTBOX-email_confirmed');
  const emailNewsletter = localStorage.getItem('LIGHTBOX-email_newsletter');
  const language = localStorage.getItem('LIGHTBOX-language');
  const country = localStorage.getItem('LIGHTBOX-country');
  return {
    email,
    emailConfirmed,
    emailNewsletter,
    language,
    country,
  };
};

export default LightboxUtils;
