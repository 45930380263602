/**
 * Replacement for component from ReactStrap, removing popper dependency
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, DropdownContext } from 'reactstrap';
import { mapToCssModules, tagPropType } from 'reactstrap/src/utils';

const DropdownToggle = (props) => {
  const {
    className,
    color,
    cssModule,
    caret,
    split,
    nav,
    tag,
    innerRef,
    disabled,
    ...restProps
  } = props;

  const context = useContext(DropdownContext);

  const onClick = (e) => {
    if (props.disabled || context.disabled) {
      e.preventDefault();
      return;
    }

    if (props.nav && !props.tag) {
      e.preventDefault();
    }

    if (props.onClick) {
      props.onClick(e);
    }

    context.toggle(e);
  };

  const getRole = () => context.menuRole || props['aria-haspopup'];

  const ariaLabel = restProps['aria-label'] || 'Toggle Dropdown';
  const classes = mapToCssModules(classNames(
    className,
    {
      'dropdown-toggle': caret || split,
      'dropdown-toggle-split': split,
      'nav-link': nav,
    },
  ), cssModule);
  const children =
    typeof restProps.children !== 'undefined' ? (
      restProps.children
    ) : (
      <span className="sr-only">{ariaLabel}</span>
    );

  let Tag;

  if (nav && !tag) {
    Tag = 'a';
    restProps.href = '#';
  } else if (!tag) {
    Tag = Button;
    restProps.color = color;
    restProps.cssModule = cssModule;
  } else {
    Tag = tag;
  }

  return (
    <Tag
      {...restProps}
      className={classes}
      aria-expanded={context.isOpen}
      aria-haspopup={getRole()}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};


const propTypes = {
  caret: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  'aria-haspopup': PropTypes.bool,
  split: PropTypes.bool,
  tag: tagPropType,
  nav: PropTypes.bool,
};

const defaultProps = {
  color: 'secondary',
  'aria-haspopup': true,
};


DropdownToggle.propTypes = propTypes;
DropdownToggle.defaultProps = defaultProps;

export default DropdownToggle;
