import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';

import { supportUrl } from 'Config/lang';
import BundlesLink from 'Components/NavBar/BundlesLink';
import CategoriesLink from 'Components/NavBar/CategoriesLink';
import DiscoverLink from 'Components/NavBar/DiscoverLink';
import StarDealButton from 'Containers/Product/StarDealButton';
import store from 'Redux/store';
import useViewport from 'Hooks/useViewport';

const onLinkClick = (link, context = 'Generic') => {
  // Context is used to determine which link was clicked
  store.dispatch({
    type: 'frontend/click/CLICK_INTERACTION',
    payload: { section: 'SecondaryNavLinks', value: link, context },
  });
};

const SecondaryNavBar = ({
  setMegaMenuTab,
  megaMenuTab,
  siteBrand,
}) => {
  const { isAboveMd } = useViewport();

  const showGiftGuide = false;
  return (
    <div className="secondary-navbar">
      <Container>
        <div className="left-links-container">
          <DiscoverLink
            setMegaMenuTab={setMegaMenuTab}
            megaMenuTab={megaMenuTab}
          />
          <CategoriesLink
            setMegaMenuTab={setMegaMenuTab}
            megaMenuTab={megaMenuTab}
          />
          <BundlesLink
            setMegaMenuTab={setMegaMenuTab}
            megaMenuTab={megaMenuTab}
          />

          <Link to="/upcoming-games" className="secondary-nav-link" onClick={() => onLinkClick('/upcoming-games', 'UpcomingGames')}>
            <FormattedMessage id="nav-secondary.link.comingsoon" defaultMessage="Upcoming Games" />
          </Link>

          <Link to="/new-releases" className="secondary-nav-link" onClick={() => onLinkClick('/new-releases', 'NewReleases')}>
            <FormattedMessage id="nav-secondary.link.newreleases" defaultMessage="New Releases" />
          </Link>

          {!['de'].includes(window.locale) &&
            <Link to="/mystery" className="secondary-nav-link" onClick={() => onLinkClick('/mystery', 'Mystery')}>
              <FormattedMessage id="nav-secondary.link.mystery" defaultMessage="Mystery" />
            </Link>
          }

          <Link to="/fantasyverse" className="secondary-nav-link" onClick={() => onLinkClick('/fantasyverse', 'FantasyVerse')}>
            FantasyVerse
          </Link>

          <StarDealButton className="secondary-nav-link" />

          {showGiftGuide &&
            <Link to="/gift-guide" className="secondary-nav-link" onClick={() => onLinkClick('/gift-guide', 'GiftGuide')}>
              <FormattedMessage id="nav-secondary.link.gift-guide" defaultMessage="Gift Guide" />
            </Link>
          }
        </div>

        <div className="right-links-container">
          {siteBrand === 'hpomen' &&
            <Link to="/redeem-code" className="secondary-nav-link" onClick={() => onLinkClick('/redeem-code', 'RedeemCode')}>
              <FormattedMessage id="nav-secondary.link.redeem-code" defaultMessage="Redeem" />
            </Link>
          }
          {(!['de', 'es', 'nl'].includes(window.locale) || (['de', 'es', 'nl'].includes(window.locale) && isAboveMd)) &&
            <a href={`${supportUrl()}`} target="_blank" rel="noopener noreferrer" className="secondary-nav-link" onClick={() => onLinkClick(`${supportUrl()}`, 'Support')}>
              <FormattedMessage id="nav-secondary.link.support" defaultMessage="Support" />
            </a>
          }
          {window.locale === 'en' && siteBrand === 'fanatical' &&
            <Link to="/blog" className="secondary-nav-link" onClick={() => onLinkClick('/blog', 'Blog')}>
              <FormattedMessage id="nav-secondary.link.blog" defaultMessage="Blog" />
            </Link>
          }
        </div>

      </Container>
    </div>
  );
};

SecondaryNavBar.propTypes = {
  setMegaMenuTab: PropTypes.func,
  megaMenuTab: PropTypes.string,
  siteBrand: PropTypes.string,
};

SecondaryNavBar.defaultProps = {
  setMegaMenuTab: () => {},
  megaMenuTab: '',
  siteBrand: 'fanatical',
};

export default SecondaryNavBar;
