import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import EmailSubscribeInput from 'Components/EmailSubscribe/EmailSubscribeInput';

import 'Components/Product/Usp/SmallUspBar.scss';

const EmailSubscribeBar = ({ mobileEnabled, appearsOn }) =>
  <div className="small-usp-bar">
    <div className="EmailSubscribeBar container">
      <div className="EmailSubscribeBar__copy">
        <FontAwesomeIcon icon={['fab', 'telegram-plane']} />
        {mobileEnabled ?
          <FormattedMessage
            id="email-subscribe-bar.mobile-message"
            defaultMessage="Sign-up for Fanatical's Newsletter!"
          /> :
          <FormattedMessage
            id="email-subscribe-bar.desktop-message"
            defaultMessage="Get great game deals! Sign-up for the Fanatical Newsletter now!"
          />
        }
      </div>
      <EmailSubscribeInput mobileEnabled={mobileEnabled} appearsOn={appearsOn} />
    </div>
  </div>;

EmailSubscribeBar.propTypes = {
  mobileEnabled: PropTypes.bool,
};

EmailSubscribeBar.defaultProps = {
  mobileEnabled: false,
};

export default EmailSubscribeBar;
