import React, { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useIntl } from 'react-intl';
import get from 'lodash/get';

import ErrorBoundary from 'Components/Utility/ErrorBoundary';
import nrWrap from 'Helpers/newrelic';

const NotFound = lazy(() => import(/* webpackChunkName: "Pages" */ 'Components/NotFound/NotFound'));

let hasTrackedPage = false;

export default function RouteWrapper({
  noPrerender,
  component: Component,
  render,
  componentProps,
  ...rest
}) {
  // Send "route name" to new relic for grouping urls
  // This seems to be the only place we have access to the path and computedMatch
  useEffect(() => {
    let route = rest.path;

    if (route) { // Very occasionally path is not included
      const { computedMatch } = rest;

      // Some routes have enumerated paths, ie. /:facet(publishers|franchises|collections|categories)/:slug
      // This is a lot of extra text to send so we replace the enum group with the actual value, if possible
      // i.e. /publishers/:slug
      const match = route.match(/^\/:(\w+)\([\w|-]+\)\/(.*)/);
      if (match) {
        const key = match[1];
        const param = get(computedMatch, `params.${key}`);
        const suffix = match[2];

        if (param) {
          route = `/${param}/${suffix}`;
        }
      }

      nrWrap((newrelic) => {
        if (!hasTrackedPage) {
          hasTrackedPage = true;
          newrelic.setPageViewName(route);
        }
        newrelic.setCurrentRouteName(route);
        newrelic.setCustomAttribute('route', route);
      });
    }
  });

  const intl = useIntl();
  if (noPrerender && navigator.userAgent.includes('Prerender')) {
    return (
      <Suspense fallback={<div />}>
        <NotFound />
      </Suspense>
    );
  }
  return (<Route
    {...rest}
    render={render || (props => (
      <ErrorBoundary>
        <Component intl={intl} {...props} {...componentProps} />
      </ErrorBoundary>
    ))}
  />);
}

RouteWrapper.type = Route;

RouteWrapper.propTypes = {
  noPrerender: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]),
  render: PropTypes.func,
  componentProps: PropTypes.shape({}),
};

RouteWrapper.defaultProps = {
  noPrerender: false,
  render: null,
  component: null,
  componentProps: {},
};
