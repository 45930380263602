import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * A custom hook that returns a stable reference unless the deeply compared value changes
 * does a deep comparison between the previous value and the current value
 * @param {*} value
 * @returns {*} stable reference
 */
const useDeepCompareRef = (value) => {
  const ref = useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export default useDeepCompareRef;
