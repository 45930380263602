import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { currencyCode } from 'Helpers/Geo/data';
import SpecialFormattedNumber from 'Containers/Utility/Price/SpecialFormattedNumber';

export default function PriceContainer({
  discountPercent,
  price,
  slug,
  tiered,
  hideDiscount,
  discountPercentOverride,
  type,
  showAsterisk,
  fullPrice,
  topSeller,
  mystery,
  drm,
  payWhatYouWant,
  giftButton,
}) {
  let showFrom = (tiered && (type === 'bundle' || type === 'pick-and-mix')) || payWhatYouWant || slug === 'utalk-single-language' || mystery;
  const safeDiscountPercent = typeof discountPercent === 'number' ? discountPercent.toFixed(0) : null;

  const singleTierPnm = !tiered && type === 'pick-and-mix';

  if (!tiered && payWhatYouWant && giftButton) showFrom = false;

  /* eslint-disable react/style-prop-object */
  return (
    <div className={classNames('card-price-container', { 'top-seller': topSeller }, { tiered })}>
      {hideDiscount &&
        <div className="price-wasprice-container">
          <span className="card-price" aria-label={`Now ${price[currencyCode]} ${currencyCode}`}>
            {showFrom &&
              <div className="from-container">
                <FormattedMessage id="hitcard.splittest.pricefrom" defaultMessage="From" />
              </div>
            }
            <SpecialFormattedNumber
              className="btn btn-primary"
              value={price[currencyCode]}
              style="currency"
            />
          </span>
        </div>
      }
      {!hideDiscount &&
        <React.Fragment>
          {!mystery && drm !== 'utalk' &&
            !discountPercentOverride && (discountPercent > 0 && discountPercent < 100)
            && !payWhatYouWant &&
            <div className="card-saving">
              {(tiered || singleTierPnm) && !payWhatYouWant &&
                <div className="tiered-text">
                  <FormattedMessage id="hitcard.splittest.upto" defaultMessage="Up to" />
                </div>
              }
              <div aria-label={`${safeDiscountPercent} percent off`}>
                {`-${safeDiscountPercent}%`}
              </div>
            </div>
          }
          {discountPercentOverride > 0 && !payWhatYouWant &&
            <div className="card-saving" aria-label={`${discountPercentOverride} percent off`}>
              {`-${discountPercentOverride}%`}
            </div>
          }
          <div className="price-wasprice-container">
            {(((discountPercent > 0
              && !showFrom))) && !payWhatYouWant && !singleTierPnm &&
              <div className="was-price" aria-label={`Original price ${fullPrice[currencyCode]} ${currencyCode}`}>
                <SpecialFormattedNumber
                  value={fullPrice[currencyCode]}
                  style="currency"
                />
              </div>
            }
            <span className="card-price" aria-label={`Now ${price[currencyCode]} ${currencyCode}`}>
              {showFrom &&
                <div className="from-container">
                  <FormattedMessage id="hitcard.splittest.pricefrom" defaultMessage="From" />
                </div>
              }

              <SpecialFormattedNumber
                className="btn btn-primary"
                value={price[currencyCode]}
                style="currency"
              />
              {showAsterisk &&
                <span>*</span>
              }
            </span>
          </div>
        </React.Fragment>
      }
    </div>
  );
}

PriceContainer.propTypes = {
  discountPercent: PropTypes.number,
  slug: PropTypes.string,
  tiered: PropTypes.bool,
  showAsterisk: PropTypes.bool,
  hideDiscount: PropTypes.bool,
  price: PropTypes.shape({
    GBP: PropTypes.number,
    EUR: PropTypes.number,
    USD: PropTypes.number,
    CAD: PropTypes.number,
    AUD: PropTypes.number,
    RUB: PropTypes.number,
    JPY: PropTypes.number,
  }),
  discountPercentOverride: PropTypes.number,
  type: PropTypes.string,
  fullPrice: PropTypes.shape({
    GBP: PropTypes.number,
    EUR: PropTypes.number,
    USD: PropTypes.number,
    CAD: PropTypes.number,
    AUD: PropTypes.number,
    RUB: PropTypes.number,
    JPY: PropTypes.number,
  }),
  topSeller: PropTypes.bool,
  mystery: PropTypes.bool,
  drm: PropTypes.string,
  payWhatYouWant: PropTypes.bool,
  giftButton: PropTypes.bool, // does the hitcard have a gift button
};

PriceContainer.defaultProps = {
  slug: '',
  discountPercent: 0,
  tiered: false,
  showAsterisk: false,
  hideDiscount: false,
  price: {
    GBP: 0,
    EUR: 0,
    USD: 0,
    CAD: 0,
    AUD: 0,
    RUB: 0,
    JPY: 0,
  },
  discountPercentOverride: 0,
  type: 'game',
  fullPrice: {
    GBP: 0,
    EUR: 0,
    USD: 0,
    CAD: 0,
    AUD: 0,
    RUB: 0,
    JPY: 0,
  },
  topSeller: false,
  mystery: false,
  drm: '',
  payWhatYouWant: false,
  giftButton: false,
};
