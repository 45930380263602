import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';

import env from 'Env';
import imgixConfig from 'Config/imgix';
import useViewport from 'Hooks/useViewport';
import useSize from 'Hooks/useSize';


// Default sizes for srsSet
const defaultSizes = [
  1920,
  1280,
  960,
  720,
  600,
  480,
  360,
  240,
  150,
];

const FluidImg = forwardRef(({
  size,
  src,
  alt,
  className,
  folder,
  subfolder,
  overrideWidth,
  sizesOverride,
  ratio16by9,
  fullQuality,
  invert,
  dpr,
  lazy,
  youtubeId,
  greyscale,
}, ref) => {
  const { width } = size;
  const { width: windowWidth } = useViewport();

  let proportionalWidth = round((width / windowWidth) * 100);
  if (!proportionalWidth || proportionalWidth > 100) {
    proportionalWidth = 33;
  }

  let subfolderString = subfolder;

  if (folder === 'product' && !subfolder) {
    subfolderString = 'original';
  }

  if (/\.svg$/.test(src)) {
    const root = env.srcCdnUrl || env.cdnUrl;
    const imgSrc = `${root}/${folder}${subfolderString ? '/' : ''}${subfolderString}/${src}`;

    return (<img
      ref={ref}
      src={imgSrc}
      alt={alt}
      className={`img-fluid img-full ${className}`}
      loading={lazy ? 'lazy' : 'eager'}
      fetchpriority={lazy ? undefined : 'high'}
    />);
  }

  let imgSrc = `${env.cdnUrl}/${folder}${subfolderString ? '/' : ''}${subfolderString}/${src}`;

  if (youtubeId) {
    imgSrc = `https://ytimg.imgix.net/${youtubeId}`;
  }

  // With the sizesOverride, The browser selects the first size for which the query matches.
  // Example: (min-width: 1200px) 260px, (min-width: 992px) 170px, (min-width: 768px) 220px, 100vw
  // The image sizes helper can be used to convert bootstrap column widths to these values

  const imgixParameters = `${imgixConfig}${greyscale ? '&sat=-100' : ''}${invert ? '&invert=true' : ''}${dpr > 1 ? `&dpr=${dpr}` : ''}`;

  // Default width for src attribute
  let maxDefaultWidth = 400;

  // Array of widths to build srcset from
  let widths = [...defaultSizes];

  // Indclude override width or computed proportional width in srcSet
  if (overrideWidth && !sizesOverride) {
    widths.push(round((overrideWidth / 100) * windowWidth));
  } else if (proportionalWidth && !sizesOverride) {
    widths.push(round((proportionalWidth / 100) * windowWidth));
  }

  if (sizesOverride) {
    // Parse image widths from sizesOverride string
    const sizeStrings = sizesOverride.split(',').map(s => s.trim());

    const pixelSizes = sizeStrings
      .filter(s => s.includes('px'))
      .map(s => s.match(/(\d+)px$/))
      .filter(a => a && a[1])
      .map(a => a && a[1])
      .map(Number);

    const vwSizes = sizeStrings
      .filter(s => s.includes('vw'))
      .map(s => s.match(/(\d+)vw$/))
      .filter(a => a && a[1])
      .map(a => a && a[1])
      .map(Number)
      .map(vw => round((vw / 100) * windowWidth));

    // New srcset widths as numbers (px) (indluding x2 sizes for DPR x2)
    const newWidths = [
      ...pixelSizes,
      ...pixelSizes.map(s => s * 2),
      ...vwSizes,
      ...vwSizes.map(s => s * 2),
    ];

    if (pixelSizes.length) {
      maxDefaultWidth = [...pixelSizes].sort((a, b) => b - a)[0];
    }

    // Combine new widths with defaults.
    widths = [
      ...newWidths,
      // Remove any defaults that fall within 15% of a new one
      ...widths.filter(s => newWidths.every(nw => !((nw / s) < 1.15 && (nw / s) > 0.85))),
    ];
  }

  const enhancedSizes = [...widths].sort((a, b) => b - a).map(w => ({
    width: w,
    height: round((w / 16) * 9),
    fullQualityEnabled: w > 950,
  }));

  const sizeToSrc = s => `${imgSrc}?${imgixParameters}&w=${s.width}${ratio16by9 ? `&fit=crop&h=${s.height}` : '&fit=max'}${s.fullQualityEnabled && fullQuality ? '&q=95' : ''}`;

  const srcSet = enhancedSizes.map(s => `${sizeToSrc(s)} ${s.width}w`).join(', ');

  const defaultSrc = sizeToSrc(enhancedSizes.find(s => s.width <= maxDefaultWidth));

  return (
    <img
      ref={ref}
      srcSet={srcSet}
      sizes={sizesOverride || `${overrideWidth || proportionalWidth}vw`}
      src={defaultSrc}
      alt={alt}
      loading={lazy ? 'lazy' : 'eager'}
      fetchpriority={lazy ? 'low' : 'high'}
      decoding={lazy ? 'async' : undefined}
      className={`img-fluid img-full ${className}`}
    />
  );
});

FluidImg.propTypes = {
  size: PropTypes.shape({}),
  src: PropTypes.string,
  ratio16by9: PropTypes.bool,
  fullQuality: PropTypes.bool,
  alt: PropTypes.string,
  className: PropTypes.string,
  subfolder: PropTypes.string,
  folder: PropTypes.string,
  overrideWidth: PropTypes.string,
  sizesOverride: PropTypes.string,
  youtubeId: PropTypes.string,
  invert: PropTypes.bool,
  lazy: PropTypes.bool,
  greyscale: PropTypes.bool,
  dpr: PropTypes.number,
};

FluidImg.defaultProps = {
  size: {},
  src: '',
  alt: '',
  className: '',
  ratio16by9: false,
  fullQuality: false,
  folder: 'product',
  subfolder: '',
  overrideWidth: '',
  sizesOverride: '',
  youtubeId: '',
  invert: false,
  greyscale: false,
  dpr: 1,
  lazy: true,
};

function FluidImgWrapper(props) {
  const [ref, size] = useSize();

  if (props.overrideWidth || props.sizesOverride) {
    return (<FluidImg {...props} />);
  }

  return (<FluidImg ref={ref} size={size} {...props} />);
}

FluidImgWrapper.propTypes = {
  overrideWidth: PropTypes.string,
  sizesOverride: PropTypes.string,
};

FluidImgWrapper.defaultProps = {
  overrideWidth: '',
  sizesOverride: '',
};

export default FluidImgWrapper;


