import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';

import { setAnonId } from 'Redux/ducks/anonymous';

function AnonymousId({ id, set }) {
  useEffect(() => {
    if (!id) {
      set(uuidV4());
    }
  }, [id, set]);

  return false;
}

AnonymousId.propTypes = {
  id: PropTypes.string,
  set: PropTypes.func.isRequired,
};

AnonymousId.defaultProps = {
  id: null,
};

export default connect(({ anonymous: { id } }) => ({ id }), { set: setAnonId })(AnonymousId);
